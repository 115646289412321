export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_DETAILS_SUBMITTED = "SET_DETAILS_SUBMITTED";


export const ROLE_ADMIN = "Admin";
export const ROLE_DEALER = "Dealer";
export const ROLE_CUSTOMER = "Customer";

export const DEFAULT_PRODUCTS = ["Walking Frame", "Rollators", "Wheelchair", "Riser Recliner Chairs", "Mobility Scooters"];
export const RATIOS = ["seat_height", "seat_depth", "foot_to_knee", "default"];
export const RATIO_VALUES = { seat_height: 1.0, seat_depth: 1.1, foot_to_knee: 1.3, default: 1.0 };