import * as posedetection from '@tensorflow-models/pose-detection';
import * as bodySegmentation from '@tensorflow-models/body-segmentation';

import { STATE } from './params';
import {RendererCanvas2d} from './renderer_canvas2d';
import { setBackendAndEnvFlags, getRatio, getDistance, SPEC_RATIO, MEASURE_TYPE_SIT_SIDE, getDistanceNoUnit, MEASURE_TYPE_STAND_SIDE } from './util';
import {setupDatGui} from './option_panel';

import React, { useState, useEffect, useRef, useReducer, useCallback } from "react";
import { Page, Grid, Card, Table, Alert, Form, Button } from "tabler-react";
import { Modal } from "react-bootstrap";
import Guides from "@scena/react-guides";

import SiteWrapper from "../../SiteWrapper";

import * as measureService from "../../store/services/measureServices";
import * as productServices from '../../store/services/productServices';

import { ReactComponent as GuidesSvg } from '../../assets/svgs/guides.svg';
import { ReactComponent as PlusSvg } from '../../assets/svgs/plus.svg';
import { ReactComponent as MinusSvg } from '../../assets/svgs/minus.svg';
import { ReactComponent as RulerSvg } from '../../assets/svgs/ruler.svg';
import { ReactComponent as MeasureSvg } from '../../assets/svgs/measure.svg';


// tensorflow module


import * as mpPose from '@mediapipe/pose';
import * as mpSegmentation from '@mediapipe/selfie_segmentation';
import * as tfjsWasm from '@tensorflow/tfjs-backend-wasm';
import * as tf from '@tensorflow/tfjs-core';

import '@tensorflow/tfjs-backend-webgl';
import '@tensorflow/tfjs-backend-webgpu';
import { ROLE_ADMIN, RATIOS, RATIO_VALUES } from '../../store/types';
import { BACKENDURL } from '../../store/services/type';

tfjsWasm.setWasmPaths(
    `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${
        tfjsWasm.version_wasm}/dist/`);


export const measuresConfig = [
    { name: "shoulderWidth", label: "Shoulder Width", distance: 0, confidence: 0 },
    { name: "hipWidth", label: "Hip Width", distance: 0, confidence: 0 },
    { name: "rightHeight", label: "Height (Right)", distance: 0, confidence: 0 },
    { name: "leftHeight", label: "Height (Left)", distance: 0, confidence: 0 },
    { name: "rightFootToKnee", label:"Foot to Knee (Right)", distance: 0, confidence: 0 },
    { name: "leftFootToKnee", label: "Foot to Knee (Left)", distance: 0, confidence: 0 },
    { name: "rightKneeToHip", label: "Knee to Hip (Right)", distance: 0, confidence: 0 },
    { name: "leftKneeToHip", label: "Knee to Hip (Left)", distance: 0, confidence: 0 },
    { name: "rightHipToShoulder", label: "Hip to Shoudler (Right)", distance: 0, confidence: 0 },
    { name: "leftHipToShoulder", label: "Hip to Shoudler (Left)", distance: 0, confidence: 0 },
    { name: "rightHipToHead", label: "Hip to Head (Right)",  distance: 0, confidence: 0 },
    { name: "leftHipToHead", label: "Hip to Head (Left)", distance: 0, confidence: 0 },
    { name: "rightElbowToWrist", label: "Elbow to Wrist (Right)", distance: 0, confidence: 0 },
    { name: "leftElbowToWrist", label: "Elbow to Wrist (Left)", distance: 0, confidence: 0 },
]

const RULER_WIDTH   = 26;
const DOT_DIFF      = 10;

const search = window.location.search;
const params = new URLSearchParams(search);

function ViewMeasure(props) {

    const [user, setUser] = useState((localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))) || {});

    const [ imageCounts, setImageCounts ] = useState(Number(params.get("count") || 1));
    const [ measureType, setMeasureType ] = useState(params.get(("measure")) || "");
    const [ metric, setMetric ] = useState(params.get("metric") || "ratio");

    const canvasRef = useRef(null);
    const imageRef = useRef(null);
    const vRuler = useRef(null);
    const hRuler = useRef(null);

    const [ ctx, setCtx ] = useState();
    const [ loading, setLoading ] = useState(true);
    const [ measure, setMeasure ] = useState([]);
    const [ tags, setTags ] = useState([]);
    const [ detector, setDetector ] = useState();
    const [ rendererImage, setRendererImage ] = useState();
    const [ base64data, setBase64data ] = useState();
    const [ base64dataArray, setBase64dataArray ] = useState([]);
    const [ poses, setPoses ] = useState();
    const [ posesArray, setPosesArray ] = useState([]);
    const [ defaultMeasureValues, setDefaultMeasureValues ] = useState([]);
    const [ defaultMeasureValuesDB, setDefaultMeasureValuesDB ] = useState([]);
    // const [ appMeasureValues, setAppMeasureValues ] = useState([]);
    const [ customMeasureValues, setCustomMeasureValues ] = useState([]);
    const [ realMeasureValues, setRealMeasureValues ] = useState([]);
    const [ customMeasureValuesDB, setCustomMeasureValuesDB ] = useState([]);
    const [ measureRatio, setMeasureRatio ] = useState(1);
    const [ measuresRatios, setMeasuresRatios ] = useState([]);
    const [ dragStart, setDragStart ] = useState();
    const [ isDrawing, setIsDrawing ] = useState(false);
    const [ isDrawed, setIsDrawed ] = useState(false);
    const [ redrawed, setRedrawed ] = useState(false);

    const [ isDot, setIsDot ] = useState(false);
    const [ newPointPair, setNewPointPair ] = useState([]);
    const [ pointPairs, setPointPairs ] = useState([]);
    const [ customMeasureSaved, setCustomMeasureSaved ] = useState(false);
    const [ initFlag, setInitFlag ] = useState(true);

    const [ handleModalShow, setHandleModalShow ] = useState(false);
    const [ handleTagModalShow, setHandleTagModalShow ] = useState(false);
    const [ handleAIProcessing, setHandleAIProcessing ] = useState(false);
    const [ selectedTag, setSelectedTag ] = useState("");
    const [ zoomLevel, setZoomLevel ] = useState(0);
    const [ unit, setUnit ] = useState("inches");
    const [ specRatio, setSpecRatio ] = useState(1);

    const [ guidePoses, setGuidePoses ] = useState([{x: 0, y: 0}, {x: 0, y: 0}]);
    const [ guidesEnable, setGuidesEnable ] = useState(false);
    const [ showRatioButtons, setShowRatioButtons ] = useState(false);
    const [ selectedRatio, setSelectedRatio ] = useState(1);
    const [ dotPressed, setDotPressed ] = useState( 0 );

    const [ search, setSearch ] = useState('');
    const [ products, setProducts ] = useState([]);
    const [ savedProducts, setSavedProducts ] = useState([]);


    useEffect(() => {
        const runInitLibrary = async() => {
            await setupDatGui("urlParams");
            await setBackendAndEnvFlags(STATE.flags, STATE.backend);
            await tf.ready();
    
            const det = await createDetector(posedetection.SupportedModels.BlazePose);

            const response = await productServices
                .getProducts(0, 0, 0);
            
            const { data } = JSON.parse(response.data);
            setProducts(data);

            setDetector(det);
            if(props.match.params.id) {
                measureService
                    .getMeasure( props.match.params.id )
                    .then(res => {
                        const data = JSON.parse(res.data);
                        console.log("data => ", data.data)
                        console.log("ratios => ", JSON.parse(data.data.app_measures || "[]"));
                        setTags(data?.tags);
                        setMeasure(data.data);
                        // setMeasure({...data.data, isNew: 0});
                        setMeasureRatio(data?.data?.ratio);
                        setDefaultMeasureValuesDB(data?.data?.default_measures);
                        setPointPairs(JSON.parse(data.data.added_measures) || []);
                        setCustomMeasureValuesDB(data.data.custom_measures || {});
                        setSavedProducts(data.data.products || []);
                        setPoses(data.data.poses);
                        setHandleAIProcessing( data.data.isNew == 1 && !data.data.ratio )
                        setSelectedRatio( data?.data?.posture == "sit_side" ? 1: 3 );

                    })
                    .catch((err) => {
                        console.log(err);
                    })
            }

        }

        runInitLibrary();

    }, [  ])



    useEffect(() => {
        const getBase64Data = async ( url ) => {
            measureService
                .getBase64Data(url)
                .then(({ data }) => {
                    if( measure?.isNew ) {
                        setBase64data(data.base64Image);
                    } else {
                        const sameArr = [];
                        for (let index = 0; index < imageCounts; index++) {
                            sameArr.push(data.base64Image);
                        }
                        setBase64dataArray(sameArr);
                    }
                })
        }

        if(measure?.filePath) {
            getBase64Data(measure?.filePath);
        }
    }, [ measure?.filePath ])

    useEffect(() => {
        const getBase64DataArray = async ( id ) => {
            measureService
                .getBase64DataArray( id )
                .then(({ data }) => {
                    if( imageCounts > 0 ) { 
                        const sameArr = [];
                        for (let index = 0; index < imageCounts; index++) {
                            for (let i = 0; i < data.base64ImageArray.length; i++) {
                                sameArr.push(data.base64ImageArray[i]);
                            }
                        }
                        setBase64dataArray(sameArr);
                    } else {
                        setBase64dataArray(data.base64ImageArray);
                    }
                    // setBase64data(data.base64ImageArray[0])
                    // imagesPosesDetect();
                })
                .catch((err) => {
                    console.log(err);
                })
        }

        if( !measure?.isNew && measure?.filesPaths && JSON.parse(measure?.filesPaths)?.length > 0 ) {
            getBase64DataArray( measure?.id )
        }
    }, [ measure?.filesPaths ])



    useEffect(() => {

        if(!ctx) return;

        const canvas = canvasRef?.current;
    
        const handleMouseDown = (evt) => {
            document.body.style.mozUserSelect = document.body.style.webkitUserSelect = document.body.style.userSelect = 'none';

            const xV = (canvas.width / canvas.offsetWidth);
            const yV = (canvas.height / canvas.offsetHeight);

            const lastX = (evt.offsetX || (evt.pageX - canvas.offsetLeft)) * xV;
            const lastY = (evt.offsetY || (evt.pageY - canvas.offsetTop)) * yV;
            const pos = ctx.transformedPoint(lastX, lastY);
            setDragStart( {x: pos.x, y: pos.y} );
            setIsDrawing( true );
            setIsDrawed(false);
        };
    
        const handleMouseMove = (evt) => {
            if (!isDrawing) return;

            const xV = canvas.width / canvas.offsetWidth;
            const yV = canvas.height / canvas.offsetHeight;
    
            // const lastX = evt.offsetX || (evt.pageX - canvas.offsetLeft);
            // const lastY = evt.offsetY || (evt.pageY - canvas.offsetTop);
            const lastX = (evt.offsetX || (evt.pageX - canvas.offsetLeft)) * xV;
            const lastY = (evt.offsetY || (evt.pageY - canvas.offsetTop)) * yV;
            
            setIsDot(false);
            setIsDrawed(true);
            if (dragStart) {
                var pt = ctx.transformedPoint(lastX, lastY);
                ctx.translate(pt.x - dragStart.x, pt.y - dragStart.y);
                redraw();
            }
        };
    
        const handleMouseUp = () => {
            if( isDrawed ) {
                setIsDot(false);
                setDragStart(null);
                setIsDrawed(false);
            } else {
                setIsDot(true);
            }
            setIsDrawing(false);
        };
    
        canvas.addEventListener('mousedown', handleMouseDown);
        canvas.addEventListener('mousemove', handleMouseMove);
        canvas.addEventListener('mouseup', handleMouseUp);
    
        return () => {
            canvas.removeEventListener('mousedown', handleMouseDown);
            canvas.removeEventListener('mousemove', handleMouseMove);
            canvas.removeEventListener('mouseup', handleMouseUp);
        };
    }, [ ctx, isDrawing, dragStart, newPointPair, isDrawed ]);

    useEffect(() => {
        if( isDot && dragStart && !isDrawed ) {
            const pairPoint = [...newPointPair, dragStart];
            setNewPointPair( pairPoint );

            rendererImage.drawKeypointCustom(dragStart);
            if( pairPoint.length === 2 ) {
                setNewPointPair([]);
                // setPointPairs([...pointPairs, pairPoint]);
                setPointPairs([...pointPairs, [...pairPoint, RATIO_VALUES[RATIOS[selectedRatio]]]]);
            }

            setIsDot(false);
        }
    }, [ isDot, dragStart ])


    useEffect(() => {
        if( measure?.isNew && rendererImage ) {
            isNewCalc();
        }

        if(measure?.isNew || !pointPairs || !poses || poses.length === 0 || !canvasRef?.current) return;

        // const ratio = getRatio(poses[0].keypoints, poses[0].keypoints3D, measure?.sideBody, measure?.posture);

        const { medianValue, index } = calculateMedianWithOriginalIndex(measuresRatios);

        console.log("real measureValue ------- ", medianValue)
        console.log("real index ------- ", index)

        // const ratio = measureRatio;
        const ratio = medianValue;

        const measures = pointPairs.map((pair, index) => {
            
            const sRatio = ratio * (pair.length === 3 ? Number(pair[2]) : 1);
            const distance = getDistance(pair, sRatio, unit);
            const distancePX = getDistance(pair, 1, "px");

            console.log(`Custom Measurement ratio ${index + 1} => ${distance} => ${distancePX}`);
            rendererImage.drawNewSkeleton(pair, distance);

            rendererImage.drawKeypointCustom(pair[0]);
            rendererImage.drawKeypointCustom(pair[1]);

            return {
                name: customMeasureValues[index]?.name ?? "custom", 
                label: customMeasureValues[index]?.label ?? "Custom", 
                distance: distance, 
                confidence: 99
            }
        });

        // setCustomMeasureValues([]);

        if(initFlag && pointPairs.length === 0 && Object.keys(customMeasureValuesDB).length === 0 && customMeasureValues.length === 0) {
            setInitFlag(false);
        } else if(initFlag && Object.keys(customMeasureValuesDB).length > 0  && pointPairs.length > 0 && customMeasureValues.length === 0) {
            let custom_mv = [];
            for (let key in customMeasureValuesDB) {
                custom_mv = [...custom_mv, customMeasureValuesDB[key]];
            }
            setCustomMeasureValues(custom_mv);
            setInitFlag(false);
        } else {
            if( metric === "ratio" ) {
                setCustomMeasureValues(measures);
            }
        }

    }, [ pointPairs, redrawed, isDrawing, measureRatio, initFlag ])

    useEffect(() => {

        const calc = async (imageRef, rendererImage, poses, zoomLevel, mRatio) => {
            if( rendererImage && poses ) {

                const image = imageRef.current;
                
                rendererImage.draw([image, poses, STATE.isModelChanged, zoomLevel]);
                
                ////////////////////////////////////// comment due metric option
                // if( defaultMeasureValuesDB?.length === 0 ) {
                //     setDefaultMeasureValues(calcMeasures(poses, measure, unit, measureRatio));
                // } else {
                //     const defaultMeasures = [ ...measuresConfig ];
                //     for (let dm of defaultMeasures) {

                //         const distanceValue = Number(defaultMeasureValuesDB[`${dm.name}`].distance);
                //         const dist = (unit === "cm" ? `${distanceValue.toFixed(2)} cm` : `${(distanceValue / 2.54).toFixed(2)} inches`);
                        
                //         dm.distance = dist;
                //         dm.confidence = defaultMeasureValuesDB[`${dm.name}`].confidence;
                //     }
                //     setDefaultMeasureValues(defaultMeasures);

                //     getRatio(poses[0].keypoints, poses[0].keypoints3D, measure?.sideBody, measure?.posture);
                //     calcMeasures(poses, measure, unit, measureRatio);
                // }

                // if(measure?.app_measures) {
                //     setAppMeasureValues(calcAppMeasures(poses, measure, JSON.parse(measure?.app_measures), unit, mRatio))
                // }
    
                setLoading(false);
            }
        }

        calc(imageRef, rendererImage, poses, zoomLevel);
    }, [ rendererImage, poses, redrawed, isDrawing, measureRatio, defaultMeasureValuesDB ])

    useEffect(() => {
        redraw();
    }, [ unit ])

    useEffect(() => {
        initGuideLines();
    }, [ guidesEnable ])

    const initGuideLines = useCallback(() => {
        if( guidesEnable && hRuler?.current && vRuler?.current ) {

            setTimeout(() => {

                const canvas = canvasRef?.current;
                const xV = canvas.width / canvas.offsetWidth;
                const yV = canvas.height / canvas.offsetHeight;

                const ele1 = document.querySelector('.scena-horizontal').querySelector(['[data-index="0"]']);
                const ele2 = document.querySelector('.scena-vertical').querySelector(['[data-index="0"]']);
                ele1.style.transform = `translateY(${ canvas.offsetHeight / 3 }px)`;
                ele2.style.transform = `translateX(${ canvas.offsetWidth / 3 }px)`;
        
                const ele3 = document.querySelector('.scena-horizontal').querySelector(['[data-index="1"]']);
                const ele4 = document.querySelector('.scena-vertical').querySelector(['[data-index="1"]']);
                ele3.style.transform = `translateY(${ canvas.offsetHeight / 3 * 2 }px)`;
                ele4.style.transform = `translateX(${ canvas.offsetWidth / 3 * 2 }px)`;

                setGuidePoses([ {x: canvas.offsetWidth / 3 + RULER_WIDTH, y: canvas.offsetHeight / 3 + RULER_WIDTH}, {x: canvas.offsetWidth/3 * 2 + RULER_WIDTH, y: canvas.offsetHeight / 3 + RULER_WIDTH} ]);
            })
        }
    }, [ canvasRef, guidesEnable ])

    const isNewCalc = useCallback(() => {
        if( newPointPair?.length === 0 ) {
            redraw();
        }

        if( measure?.posture != MEASURE_TYPE_STAND_SIDE ) {
            setDefaultMeasureValues(calcMeasures(measure?.poses, measure, unit, measure?.ratio * 1.1));
        } else {
            setDefaultMeasureValues(calcMeasures(measure?.poses, measure, unit, measure?.ratio ));
        }

        const ratio = measure?.ratio;
        let sRatio = ratio;
        if( selectedRatio < 3 ) {
            sRatio = measure?.ratio * (RATIO_VALUES[RATIOS[selectedRatio]]);
        }

        const measures = pointPairs.map((pair, index) => {
            
            const pairRatio = ratio * Number(pair[2]);
            // const distance = getDistance(pair, measure?.posture != MEASURE_TYPE_STAND_SIDE ? pairRatio * 1.1 : pairRatio , unit);
            const distance = getDistance(pair, pairRatio , unit);
            const distancePX = getDistance(pair, 1, "px");

            console.log(`Custom Measurement distance ${index + 1} => ${distance} => ${distancePX}`);

            if( rendererImage ) {
                rendererImage.drawNewSkeleton(pair, distance);
                rendererImage.drawKeypointCustom(pair[0]);
                rendererImage.drawKeypointCustom(pair[1]);
            }

            return {
                name: customMeasureValues[index]?.name ?? "custom", 
                label: customMeasureValues[index]?.label ?? "Custom", 
                distance: distance, 
                confidence: 99
            }
        });
        setCustomMeasureValues(measures);

        if( !rendererImage && canvasRef?.current && imageRef?.current ) {
            const canvasImage = canvasRef?.current;
            const image = imageRef?.current;
    
            const cctx = canvasImage.getContext('2d');
            canvasImage.width = image.width;
            canvasImage.height = image.height;
    
            cctx.drawImage(image, 0, 0, image.width, image.height);
    
            setRendererImage(new RendererCanvas2d(canvasImage));

            setRedrawed(!redrawed);
    
            trackTransforms(cctx);
            setCtx(cctx);
        }

        setLoading(false)

    }, [measure, pointPairs, imageRef?.current, unit, selectedRatio, rendererImage, newPointPair])

    const handleImageLoad = useCallback(async () => {
        if( measure?.isNew ) {
            isNewCalc()
        } else if( base64dataArray.length > 0 ) {
            chooseMeasure();
        } else if( measure?.filePath ) {
            setTimeout(() => {
                imagePoseDetect();
            }, 1000)
        }
    }, [base64dataArray, measure, measuresRatios] )

    const imagePoseDetect = useCallback(async () => {

        if( canvasRef?.current && imageRef?.current ) {
            const canvasImage = canvasRef?.current;
            const image = imageRef?.current;
    
            const cctx = canvasImage.getContext('2d');
            canvasImage.width = image.width;
            canvasImage.height = image.height;
    
            cctx.drawImage(image, 0, 0, image.width, image.height);
    
            setRendererImage(new RendererCanvas2d(canvasImage));
            
            const image_poses = await detector.estimatePoses(image, {maxPoses: STATE.modelConfig.maxPoses, flipHorizontal: false});

            console.log("image_poses ================= > ", image_poses);
            // if( !defaultMeasureValuesDB || Object.keys(defaultMeasureValuesDB).length !== measuresConfig.length || !poses ) {
            //     const ratio = getRatio(image_poses[0].keypoints, image_poses[0].keypoints3D, measure?.sideBody, measure?.posture);
            //     setMeasureRatio(ratio);
            //     const defaultMeasures = calcDatabaseMeasures(image_poses, ratio);

            //     for (let key in defaultMeasures) {
            //         defaultMeasures[key].distanceCM = Number(Number(defaultMeasures[key].distance).toFixed(2));
            //     }
            //     setDefaultMeasureValuesDB(defaultMeasures);

            //     measureService
            //         .updateMeasure(props.match.params.id, { default_measures: JSON.stringify(defaultMeasures), ratio: ratio, poses: JSON.stringify([{keypoints: image_poses[0].keypoints, keypoints3D: image_poses[0].keypoints3D}]) })
            //         .then(res => {
            //             console.log("default measure saved ====== >");
            //         });
            //     setPoses(image_poses);
            // } else {

            // }

            setRedrawed(!redrawed);
    
            trackTransforms(cctx);
            setCtx(cctx);
        }
    }, [ measure, defaultMeasureValuesDB, poses ])

    useEffect(() => {
        if(base64dataArray.length > 0) {
            imagesPosesDetect();
        }
    }, [ base64dataArray ])

    const imagesPosesDetect = useCallback(async () => {
        if( base64dataArray.length > 0)  {
            const allPoses = [];
            const ratios = [];
            const realDistances = [];
            const ratioDistances = [];

            for (const base64data of base64dataArray) {
                const image = new Image();
                image.src = base64data;
    
                // Wait for the image to load
                await new Promise((resolve) => {
                    image.onload = resolve;
                });
    
                // Create a temporary canvas to draw the image
                const canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;
                const cctx = canvas.getContext('2d');
                cctx.drawImage(image, 0, 0, image.width, image.height);
    
                const image_poses = await detector.estimatePoses(image, {
                    maxPoses: STATE.modelConfig.maxPoses,
                    flipHorizontal: false,
                });

                allPoses.push(image_poses);
                if( measure?.filePath ) {
                    console.log("[[[[[[[[[[[[[[[[[ filePath => ", measure?.filePath);
                } else if ( measure?.filesPaths ) {
                    console.log("[[[[[[[[[[[[[[[[[ filesPaths => ", JSON.parse(measure?.filesPaths)[Math.ceil(ratios?.length / imageCounts)]);
                }
                
                const rat = getRatio(image_poses[0].keypoints, image_poses[0].keypoints3D, measure?.sideBody, measure?.posture, measureType);
                ratios.push(rat);
                realDistances.push(calcRealMeasures(image_poses, null, unit));
                ratioDistances.push(calcMeasures(image_poses, null, unit, rat));
            }

            console.log("real table distances ---------------- ", realDistances);
            setPosesArray(allPoses);
            setMeasuresRatios(ratios);
            setRealMeasureValues(realDistances);

            const { index } = calculateMedianWithOriginalIndex(ratios);
            setBase64data(base64dataArray[index]);
            // if( metric === "distance" ) {
            //     setDefaultMeasureValues(realDistances[index])
            // } else {
            //     setDefaultMeasureValues(ratioDistances[index])
            // }
        }
    }, [ base64dataArray, measure, metric, measureType, unit ])

    const chooseMeasure = useCallback(() => {
        const { medianValue, index } = calculateMedianWithOriginalIndex(measuresRatios);

        console.log("ratios ---------------- ", measuresRatios);
        console.log("selected index ---------------- ", index);
        console.log("selected medianValue ---------------- ", medianValue);
        console.log("average value ---------------- ", (measuresRatios.reduce((a, b) => a + b, 0) / measuresRatios.length));


        if( canvasRef?.current ) {
            const canvasImage = canvasRef?.current;
            const image = imageRef?.current;
    
            const cctx = canvasImage.getContext('2d');
            canvasImage.width = image.width;
            canvasImage.height = image.height;
    
            cctx.drawImage(image, 0, 0, image.width, image.height);
    
            setRendererImage(new RendererCanvas2d(canvasImage));
    
            const image_poses = posesArray[index];

            // if( !defaultMeasureValuesDB || Object.keys(defaultMeasureValuesDB).length !== measuresConfig.length || !poses ) {
            //     // const ratio = getRatio(image_poses[0].keypoints, image_poses[0].keypoints3D, measure?.sideBody, measure?.posture);
            //     const ratio = medianValue;
            //     setMeasureRatio(ratio);
            //     const defaultMeasures = calcDatabaseMeasures(image_poses, ratio);

            //     for (let key in defaultMeasures) {
            //         defaultMeasures[key].distanceCM = Number(Number(defaultMeasures[key].distance).toFixed(2));
            //     }
            //     setDefaultMeasureValuesDB(defaultMeasures);

            //     measureService
            //         .updateMeasure(props.match.params.id, { default_measures: JSON.stringify(defaultMeasures), ratio: ratio, poses: JSON.stringify([{keypoints: image_poses[0].keypoints, keypoints3D: image_poses[0].keypoints3D}]) })
            //         .then(res => {
            //            console.log("default measure saved ====== >");
            //         });
            //     setPoses(image_poses);
            // }

            setRedrawed(!redrawed);
    
            trackTransforms(cctx);
            setCtx(cctx);
        }

    }, [ measuresRatios, realMeasureValues ])

    useEffect(() => {
        if( measure?.isNew ) {
            isNewCalc();
        }
        if( posesArray?.length > 0 && measuresRatios.length > 0 ) {
            const ratios = [];
            for (const pa of posesArray) {
                const rat = getRatio(pa[0].keypoints, pa[0].keypoints3D, measure?.sideBody, measure?.posture, measureType);
                ratios.push(rat);
            }
    
            const { medianValue, index } = calculateMedianWithOriginalIndex(ratios);
            // console.log("measuresRatios ----------------- ", measuresRatios)
            // console.log("selected index ------------ ", index)
            // console.log("selected medianValue ------------ ", medianValue)
            if( metric === "distance" ) {
                setDefaultMeasureValues(calcRealMeasures(posesArray[index], null, unit))
                const measures = pointPairs.map((pair, index) => {
            
                    const sRatio = medianValue * (pair.length === 3 ? Number(pair[2]) : 1);
                    const distance = getDistance(pair, sRatio, unit);
                    const distancePX = getDistance(pair, 1, "px");
        
                    console.log(`Custom Measurement distance ${index + 1} => ${distance} => ${distancePX}`);

                    return {
                        name: customMeasureValues[index]?.name ?? "custom", 
                        label: customMeasureValues[index]?.label ?? "Custom", 
                        distance: distance, 
                        confidence: 99
                    }
                });
                setCustomMeasureValues(measures);

            } else {
                setDefaultMeasureValues(calcMeasures(posesArray[index], null, unit, medianValue))
            }
        }
    }, [ posesArray, unit, pointPairs, measuresRatios ])

    var zoom = function (clicks) {
        if(!ctx) return;

        const level = clicks / 4.5;

        rendererImage.setZoomLevel(zoomLevel + level);
        setZoomLevel((old) => old + level);

        const canvas = canvasRef?.current;

        const lastX = canvas.width / 2;
        const lastY = canvas.height / 2;
        var pt = ctx.transformedPoint(lastX, lastY);
        ctx.translate(pt.x, pt.y);
        var factor = Math.pow(1.1, clicks);
        ctx.scale(factor, factor);
        ctx.translate(-pt.x, -pt.y);
        redraw();

        //  ############################################################

        if( guidesEnable && hRuler?.current && vRuler?.current ) {
 
            const halfY = canvas.offsetHeight / 2;
            const halfX = canvas.offsetWidth / 2;

            const ele1 = document.querySelector('.scena-horizontal').querySelector(['[data-index="0"]']);
            const ele2 = document.querySelector('.scena-vertical').querySelector(['[data-index="0"]']);

            ele1.style.transform = `translateY(${ halfY - ((halfY - parseFloat(ele1.style.transform.match(/translateY\((-?\d+(?:\.\d+)?)px\)/)[1]) - RULER_WIDTH) * factor - (factor * (clicks / 3))) - RULER_WIDTH }px)`;
            ele2.style.transform = `translateX(${ halfX - ((halfX - parseFloat(ele2.style.transform.match(/translateX\((-?\d+(?:\.\d+)?)px\)/)[1]) - RULER_WIDTH) * factor - (factor * (clicks / 3))) - RULER_WIDTH }px)`;
    
            const ele3 = document.querySelector('.scena-horizontal').querySelector(['[data-index="1"]']);
            const ele4 = document.querySelector('.scena-vertical').querySelector(['[data-index="1"]']);
            ele3.style.transform = `translateY(${ halfY - ((halfY - parseFloat(ele3.style.transform.match(/translateY\((-?\d+(?:\.\d+)?)px\)/)[1]) - RULER_WIDTH) * factor - (factor * (clicks / 3))) - RULER_WIDTH }px)`;
            ele4.style.transform = `translateX(${ halfX - ((halfX - parseFloat(ele4.style.transform.match(/translateX\((-?\d+(?:\.\d+)?)px\)/)[1]) - RULER_WIDTH) * factor - (factor * (clicks / 3))) - RULER_WIDTH }px)`;

            const firPos = getNearPos({ ...guidePoses[0] }, false);
            const secPos = getNearPos({ ...guidePoses[1] }, false);

            setGuidePoses([{x: firPos?.x, y: firPos?.y}, {x: secPos?.x, y: secPos?.y}]);
        }
    }

    const redraw = useCallback(() => {

        if( ctx ) {
            const canvasImage = canvasRef?.current;
            const image = imageRef?.current;
    
            var p1 = ctx.transformedPoint(0, 0);
            var p2 = ctx.transformedPoint(canvasImage.width, canvasImage.height);
            ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
        
            ctx.save();
            ctx.setTransform(1, 0, 0, 1, 0, 0);
            ctx.clearRect(0, 0, canvasImage.width, canvasImage.height);
            ctx.restore();
        
            ctx.drawImage(image, 0, 0, canvasImage.width, canvasImage.height);

            redrawed ? setRedrawed(false) : setRedrawed(true);
        }
    }, [ redrawed, ctx, canvasRef, imageRef ]);

    const removeMeasure = useCallback(( index ) => {
        const newArray = [...pointPairs]; // Create a copy of the array
        newArray.splice(index, 1); // Remove 1 element at the specified index
        setPointPairs(newArray);

        const newCustomArray = [...customMeasureValues]; // Create a copy of the array
        newCustomArray.splice(index, 1); // Remove 1 element at the specified index
        setCustomMeasureValues(newCustomArray);

        redraw();
    }, [ redrawed, pointPairs, ctx ]);

    const saveMeasure = useCallback(() => {

        const custom_measures = {};
        var index = 0;
        for (let c_m of customMeasureValues) {
            c_m.distanceCM = Number(getDistanceNoUnit(pointPairs[index], measureRatio));
            custom_measures[`${c_m?.name}`] = c_m;
            index ++;
        }

        // const sPP = pointPairs.map((pp) => {
        //     if( pp.length > 3 ) {
        //         return pp;
        //     } else {
        //         return [pp[0], pp[1], RATIO_VALUES[RATIOS[selectedRatio]]];
        //     }
        // })

        measureService
            .updateMeasure(props.match.params.id, { added_measures: JSON.stringify(pointPairs), custom_measures: JSON.stringify(custom_measures) })
            .then(res => {
                const data = JSON.parse(res.data);
                setMeasure(data.data);
                setPointPairs(JSON.parse(data.data.added_measures) || []);
                setCustomMeasureSaved(true);
                setTimeout(() => {
                    setCustomMeasureSaved(false);
                }, 2000)
            })
    }, [selectedRatio, pointPairs])

    const resetMeasure = useCallback(() => {
        zoom( -4.5 * zoomLevel );

        const transformMatrix = ctx.getTransform();
        ctx.translate(transformMatrix.e * -1, transformMatrix.f * -1);

        setIsDot(false);
        setDragStart(null);

        redraw();
        setPointPairs(JSON.parse(measure.added_measures) || []);

        initGuideLines();

        setZoomLevel(0);

    }, [ ctx, redrawed, guidesEnable, zoomLevel ])

    const handleClose = () => {
        setHandleModalShow(false);
    }

    const deleteMeasure = () => {
        measureService
            .deleteMeasure(props.match.params.id)
            .then(res => {
                const data = JSON.parse(res.data);
                if(data?.message === "Success") {
                    props.history.push("/measure");
                }
            })
    }

    const handleUnitChange = () => {
        unit === "cm" ? setUnit("inches") : setUnit("cm");
    }

    const handleRatioChange = () => {
        specRatio === 1 ? setSpecRatio(SPEC_RATIO) : setSpecRatio(1);
    }


    const convertDateFormat = (timestamp) => {
        if( !timestamp ) {
            return `00-00-00 00:00`;
        }
        const date = new Date(timestamp);
        const day = date.getDate();
        const month = date.getMonth() + 1; // Month is zero-based, so we add 1
        const year = date.getFullYear() % 100; // Get the last two digits of the year
        const hour = date.getHours();
        const minute = date.getMinutes();

        return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year} ${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}`;
    }

    const handleAddCustomTag = (  ) => {
        setCustomMeasureValues((prev) => {
            prev[handleTagModalShow - 1].name = selectedTag.trim().replace(" ", "_").toLocaleLowerCase();
            prev[handleTagModalShow - 1].label = selectedTag;
            return prev;
        });

        measureService
            .saveTag({ tag: selectedTag, measure_id: measure?.id })
            .then((res) => {
                console.log(res)
            })
            .catch((e) => console.log(e));
        setHandleTagModalShow(0);
    }




// ======================= guide dot move ==================

    useEffect(() => {
        if( guidesEnable && guidePoses.length === 2 ) {
            document.querySelector(".guide-dot.first-dot").style.transform = `translateX(${ guidePoses[0].x }px) translateY(${ guidePoses[0].y }px)`;
            document.querySelector(".guide-dot.second-dot").style.transform = `translateX(${ guidePoses[1].x }px) translateY(${ guidePoses[1].y }px)`;
        }
    }, [ guidePoses ])

    useEffect(() => {
        const handleMouseMove = (event) => {

            if (guidePoses.length === 2 && dotPressed) {

                const rect = document.querySelector(".guide-box").getBoundingClientRect();

                const newX = event.clientX - 5 - rect.x;
                const newY = event.clientY - 5 - rect.y;

                const { x, y } = getNearPos({ x: newX, y: newY });
                if( dotPressed === 1 ) {
                    setGuidePoses( [{ x: x, y: y }, { x: guidePoses[1].x, y: guidePoses[1].y }] );
                } else if ( dotPressed === 2 ) {
                    setGuidePoses( [{ x: guidePoses[0].x, y: guidePoses[0].y }, { x: x, y: y }] );
                }
                setIsDrawed( false );
            }
        };
    
        const handleMouseUp = () => {
            if( isDrawed && canvasRef?.current ) {

                const canvas = canvasRef?.current;
                const xV = canvas.width / canvas.offsetWidth;
                const yV = canvas.height / canvas.offsetHeight;

                const point = dotPressed === 1 ? guidePoses[0] : guidePoses[1];
                const pairPoint = [...newPointPair, { x: point.x * xV + DOT_DIFF, y: point.y * yV + DOT_DIFF } ];
                setNewPointPair( pairPoint );
    
                rendererImage.drawKeypointCustom( { x: point.x * xV + DOT_DIFF, y: point.y * yV + DOT_DIFF } );
                if( pairPoint.length === 2 ) {
                    setNewPointPair([]);
                    // setPointPairs([...pointPairs, pairPoint]);
                    setPointPairs([...pointPairs, [...pairPoint, specRatio]]);
                }
            }
            setDotPressed( 0 );
            setIsDrawed( false );
        };
    
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    
        return () => {
          document.removeEventListener('mousemove', handleMouseMove);
          document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [ guidePoses, dotPressed, isDrawed ]);

    const handleDotMouseDown = ( e ) => {
        setIsDrawed( true );

        const classNames = e.target.classList;
        setDotPressed( classNames.contains('first-dot') ? 1 : 2 );
    }


    const getNearPos = ( mouse_pos, isFit = true ) => {

        if( canvasRef?.current ) {
    
            const hLine1 = document.querySelector('.scena-horizontal').querySelector(['[data-index="0"]']).style.transform;
            const hLine2 = document.querySelector('.scena-horizontal').querySelector(['[data-index="1"]']).style.transform;
    
            const vLine1 = document.querySelector('.scena-vertical').querySelector(['[data-index="0"]']).style.transform;
            const vLine2 = document.querySelector('.scena-vertical').querySelector(['[data-index="1"]']).style.transform;

            const y1 = parseInt(hLine1.match(/translateY\((-?\d+(?:\.\d+)?)px\)/)[1]) + RULER_WIDTH;
            const y2 = parseInt(hLine2.match(/translateY\((-?\d+(?:\.\d+)?)px\)/)[1]) + RULER_WIDTH;
    
            const x1 = parseInt(vLine1.match(/translateX\((-?\d+(?:\.\d+)?)px\)/)[1]) + RULER_WIDTH;
            const x2 = parseInt(vLine2.match(/translateX\((-?\d+(?:\.\d+)?)px\)/)[1]) + RULER_WIDTH;
    
            const distances = [];
            distances.push(distancePointToLine( mouse_pos.x, mouse_pos.y, 0, y1, 1000, y1 ));
            distances.push(distancePointToLine( mouse_pos.x, mouse_pos.y, 0, y2, 1000, y2 ));
            distances.push(distancePointToLine( mouse_pos.y, mouse_pos.x, 0, x1, 1000, x1 ));
            distances.push(distancePointToLine( mouse_pos.y, mouse_pos.x, 0, x2, 1000, x2 ));

            var minIndex = 0;
            var secondMinIndex = -1; // Initialize secondMinIndex to -1, indicating it has not been found yet

            for (var i = 1; i < 4; i++) {
                // Compare the current element with the minimum value found so far
                if (distances[i] < distances[minIndex]) {
                    secondMinIndex = minIndex; // Update the secondMinIndex to the previous minIndex
                    minIndex = i; // Update the index of the minimum value
                } else if (secondMinIndex === -1 || distances[i] < distances[secondMinIndex]) {
                    secondMinIndex = i; // Update the secondMinIndex if the condition is met
                }
            }

            if(isFit) {
                if( distances[minIndex] < 10 && distances[secondMinIndex] < 10 ) {
                    if( (minIndex === 0 || minIndex === 1) && ( secondMinIndex === 2 || secondMinIndex === 3 ) ) {
                        return { x: minIndex === 0 ? x1: x2, y: secondMinIndex === 2 ? y1: y2 };
                    } else if ( (minIndex === 2 || minIndex === 3) && ( secondMinIndex === 0 || secondMinIndex === 1 ) ) {
                        return { x: minIndex === 2 ? x1: x2, y: secondMinIndex === 0 ? y1: y2 };
                    }
                } else if( distances[minIndex] < 10 ) {
                    if( minIndex === 0 ) {
                        return { x: mouse_pos.x, y: y1 };
                    } else if ( minIndex === 1 ) {
                        return { x: mouse_pos.x, y: y2 };
                    } else if ( minIndex === 2 ) {
                        return { x: x1, y: mouse_pos.y };
                    } else if ( minIndex === 3 ) {
                        return { x: x2, y: mouse_pos.y };
                    }
                } else 
                    return mouse_pos;
            } else {
                if( (minIndex === 0 || minIndex === 1) && ( secondMinIndex === 2 || secondMinIndex === 3 ) ) {
                    return { x: minIndex === 0 ? x1: x2, y: secondMinIndex === 2 ? y1: y2 };
                } else if ( (minIndex === 2 || minIndex === 3) && ( secondMinIndex === 0 || secondMinIndex === 1 ) ) {
                    return { x: minIndex === 2 ? x1: x2, y: secondMinIndex === 0 ? y1: y2 };
                }
            }
        } else {
            return mouse_pos;
        }
    }

    const calcX = (x, factor) => {
        const canvas = canvasRef?.current;
        const halfX = canvas.offsetWidth / 2;

        return ((halfX - halfX * factor - x) / factor + 2 * factor ) * -1;
    }

    const calcY = (y, factor) => {
        const canvas = canvasRef?.current;
        const halfY = canvas.offsetHeight / 2;

        return ((halfY - halfY * factor - y) / factor + 2 * factor ) * -1;
    }

    const addMeasure = () => {
        if( canvasRef?.current && guidePoses.length === 2 ) {
            const canvas = canvasRef?.current;
            const xV = canvas.width / canvas.offsetWidth;
            const yV = canvas.height / canvas.offsetHeight;

            const transformMatrix = ctx.getTransform();
            const factor = transformMatrix.a;


            const pairPoint = [ { x: calcX(guidePoses[0].x, factor) * xV + DOT_DIFF, y: calcY(guidePoses[0].y, factor) * yV + DOT_DIFF }, { x: calcX(guidePoses[1].x, factor) * xV + DOT_DIFF, y: calcY(guidePoses[1].y, factor) * yV + DOT_DIFF } ];
            setNewPointPair([]);
            
            // setPointPairs([...pointPairs, pairPoint]);
            // setPointPairs([...pointPairs, [...pairPoint, specRatio]]);
            setPointPairs([...pointPairs, [...pairPoint, RATIO_VALUES[RATIOS[selectedRatio]]]]);

            redraw();
        }
    }


    return (
        <SiteWrapper loading={loading}>
            <Page.Content>
                <div className="page-header">
                    <h1 className="page-title">View Measure</h1>
                </div>
                <Grid.Row >
                    <Grid.Col lg={8} md={12} style={{height: "700px"}}>
                        <Card className="overflow-hidden picture-container" >
                            <div className="flex-column d-flex px-3 justify-content-center align-items-start py-5" style={{ gap: '10px' }}>
                                <button className="btn d-flex align-items-center justify-content-center measure-control-btn icon-button" onClick={() => zoom(3)}>
                                    <PlusSvg />
                                </button>
                                <button className="btn d-flex align-items-center justify-content-center measure-control-btn icon-button" onClick={() => zoom(-3)}>
                                    <MinusSvg />
                                </button>
                                <button className="btn d-flex align-items-center justify-content-center measure-control-btn icon-button" onClick={() => saveMeasure()}>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.4202 3.06251L10.9375 0.579771C10.8334 0.474851 10.7095 0.391667 10.573 0.335055C10.4365 0.278443 10.2901 0.249534 10.1423 0.250006H1.375C1.07663 0.250006 0.790483 0.368532 0.579505 0.579511C0.368526 0.790489 0.25 1.07664 0.25 1.37501V12.625C0.25 12.9234 0.368526 13.2095 0.579505 13.4205C0.790483 13.6315 1.07663 13.75 1.375 13.75H12.625C12.9234 13.75 13.2095 13.6315 13.4205 13.4205C13.6315 13.2095 13.75 12.9234 13.75 12.625V3.85774C13.7505 3.70995 13.7216 3.56354 13.665 3.42702C13.6083 3.2905 13.5252 3.16659 13.4202 3.06251ZM9.8125 12.625H4.1875V8.68751H9.8125V12.625ZM12.625 12.625H10.9375V8.68751C10.9375 8.38914 10.819 8.10299 10.608 7.89201C10.397 7.68103 10.1109 7.56251 9.8125 7.56251H4.1875C3.88913 7.56251 3.60298 7.68103 3.392 7.89201C3.18103 8.10299 3.0625 8.38914 3.0625 8.68751V12.625H1.375V1.37501H10.1423L12.625 3.85774V12.625ZM9.25 3.06251C9.25 3.21169 9.19074 3.35476 9.08525 3.46025C8.97976 3.56574 8.83668 3.62501 8.6875 3.62501H4.75C4.60082 3.62501 4.45774 3.56574 4.35225 3.46025C4.24676 3.35476 4.1875 3.21169 4.1875 3.06251C4.1875 2.91332 4.24676 2.77025 4.35225 2.66476C4.45774 2.55927 4.60082 2.50001 4.75 2.50001H8.6875C8.83668 2.50001 8.97976 2.55927 9.08525 2.66476C9.19074 2.77025 9.25 2.91332 9.25 3.06251Z" fill="white"/>
                                    </svg>
                                </button>
                                <button className="btn d-flex align-items-center justify-content-center measure-control-btn icon-button" onClick={() => resetMeasure()}>
                                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.75 6.99998C14.7502 8.77459 14.0515 10.4779 12.8051 11.7412C11.5588 13.0045 9.86515 13.7261 8.0907 13.75H8C6.27611 13.7543 4.61676 13.0946 3.36641 11.9078C3.31271 11.857 3.26955 11.7961 3.23938 11.7287C3.20921 11.6612 3.19262 11.5885 3.19056 11.5146C3.1885 11.4407 3.20102 11.3672 3.22739 11.2981C3.25376 11.2291 3.29347 11.1659 3.34426 11.1122C3.39504 11.0585 3.45591 11.0153 3.52337 10.9852C3.59084 10.955 3.66359 10.9384 3.73746 10.9364C3.81134 10.9343 3.8849 10.9468 3.95394 10.9732C4.02298 10.9996 4.08615 11.0393 4.13984 11.0901C4.94408 11.8485 5.95396 12.3532 7.04335 12.541C8.13273 12.7289 9.25332 12.5915 10.2651 12.1462C11.2769 11.7009 12.1351 10.9673 12.7324 10.0371C13.3297 9.10691 13.6396 8.02133 13.6235 6.91598C13.6075 5.81064 13.266 4.73454 12.6419 3.82211C12.0178 2.90968 11.1387 2.20137 10.1144 1.78567C9.09005 1.36998 7.96594 1.26532 6.88249 1.48477C5.79903 1.70422 4.80425 2.23806 4.02242 3.01959C4.01668 3.0258 4.01057 3.03167 4.00414 3.03717L2.13523 4.74998H4.0625C4.21168 4.74998 4.35476 4.80924 4.46025 4.91473C4.56574 5.02022 4.625 5.16329 4.625 5.31248C4.625 5.46166 4.56574 5.60474 4.46025 5.71023C4.35476 5.81572 4.21168 5.87498 4.0625 5.87498H0.6875C0.538316 5.87498 0.395242 5.81572 0.289752 5.71023C0.184263 5.60474 0.125 5.46166 0.125 5.31248V1.93748C0.125 1.78829 0.184263 1.64522 0.289752 1.53973C0.395242 1.43424 0.538316 1.37498 0.6875 1.37498C0.836684 1.37498 0.979758 1.43424 1.08525 1.53973C1.19074 1.64522 1.25 1.78829 1.25 1.93748V4.03279L3.23633 2.21873C4.18131 1.27741 5.384 0.637166 6.69256 0.378812C8.00112 0.120458 9.35689 0.255579 10.5887 0.767118C11.8205 1.27866 12.8732 2.14368 13.6138 3.25299C14.3544 4.3623 14.7498 5.66616 14.75 6.99998Z" fill="white"/>
                                    </svg>
                                </button>
                                {/* <button className="btn d-flex align-items-center justify-content-center measure-control-btn icon-button" onClick={() => setGuidesEnable( !guidesEnable )}>
                                    <GuidesSvg />
                                </button> */}
                                {
                                    measure?.posture === MEASURE_TYPE_SIT_SIDE && 
                                    <div className='position-relative'>
                                        <button className={`btn d-flex align-items-center justify-content-center measure-control-btn icon-button ${ showRatioButtons ? 'selected' : '' }`} onClick={() => setShowRatioButtons( (pre) => !pre )}>
                                            <GuidesSvg />
                                        </button>
                                        {
                                            showRatioButtons && 
                                            <div className='position-absolute ratio-buttons'>
                                                <button className={`btn d-flex align-items-center justify-content-center measure-control-btn ${ selectedRatio === 0 ? 'selected': '' }`} onClick={() => setSelectedRatio(0)}>
                                                    Seat Height
                                                </button>
                                                <button className={`btn d-flex align-items-center justify-content-center measure-control-btn ${ selectedRatio === 1 ? 'selected': '' }`} onClick={() => setSelectedRatio(1)}>
                                                    Seat Depth
                                                </button>
                                                <button className={`btn d-flex align-items-center justify-content-center measure-control-btn ${ selectedRatio === 2 ? 'selected': '' }`} onClick={() => setSelectedRatio(2)}>
                                                    Foot to Knee
                                                </button>
                                            </div>
                                        }
                                    </div>
                                }

                                <div className='position-relative'>
                                    <button className={`btn d-flex align-items-center justify-content-center measure-control-btn icon-button ${ guidesEnable ? 'selected' : '' }`} onClick={() => setGuidesEnable( (pre) => !pre )}>
                                        <RulerSvg />
                                    </button>
                                    {
                                        guidesEnable && 
                                        <div className='position-absolute ratio-buttons'>
                                            <button className={`btn d-flex align-items-center justify-content-center measure-control-btn`} onClick={() => addMeasure()}>
                                            Measure
                                            </button>
                                        </div>
                                    }
                                </div>
                                {/* {
                                    guidesEnable && 
                                    <button className="btn d-flex align-items-center justify-content-center measure-control-btn" onClick={() => addMeasure()}>
                                        Measure
                                    </button>
                                } */}
                                {/* {
                                    measure?.posture === MEASURE_TYPE_SIT_SIDE && 
                                    <button href="#" className="btn my-1 d-flex align-items-center justify-content-center measure-control-btn btn-switch pr-0" >
                                        FtoK
                                        <Form.Switch
                                            name="ratio"
                                            className="ml-2"
                                            checked={ specRatio === SPEC_RATIO }
                                            onChange={handleRatioChange}
                                        ></Form.Switch> 
                                    </a>
                                } */}
                            </div>
                            <div className='position-relative w-100' style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.08)" }}>
                                {
                                    guidesEnable && 
                                    <div className='position-absolute w-100 h-100' >
                                        <div className='position-relative overflow-hidden guide-box' style={{height: "100%", width: '100%'}} >

                                            <Guides
                                                type="horizontal"
                                                displayDragPos={true}
                                                useResizeObserver={true}
                                                rulerStyle={{ height: '30px', width: '100%' }}
                                                defaultGuides={[1, 1]}
                                                lockGuides={ ['change'] }
                                                guideStyle={{ height: 2 }}
                                                ref={ hRuler }
                                            ></Guides>
                                            <Guides
                                                type="vertical"
                                                displayDragPos={true}
                                                useResizeObserver={true}
                                                rulerStyle={{ width: '30px', height: '100%' }}
                                                defaultGuides={[1, 1]}
                                                lockGuides={['change']}
                                                guideStyle={{ width: 2 }}
                                                ref={ vRuler }
                                            ></Guides>

                                            <span className='guide-dot first-dot' onMouseDown={ handleDotMouseDown } ></span>
                                            <span className='guide-dot second-dot' onMouseDown={ handleDotMouseDown }></span>

                                        </div>
                                    </div>
                                }
                                <canvas ref={ canvasRef } style={{ width: '100%' }} />

                                {
                                    base64data &&
                                    <img 
                                        src={ base64data } 
                                        ref={ imageRef } 
                                        onLoad={handleImageLoad} 
                                        style={{display: "none"}} 
                                    />
                                }
                                <div className='position-absolute d-none' style={{ left: '49px', top: '465px' }}>
                                    <div className='checking-block' style={{  }}></div>
                                    <div className='checking-block' style={{  }}></div>
                                    <div className='checking-block' style={{  }}></div>
                                    <div className='checking-block' style={{  }}></div>
                                    <div className='checking-block' style={{  }}></div>
                                </div>
                                {
                                    customMeasureSaved && 
                                    <div className='measure-saved-badge'>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check-filled" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" strokeWidth="0" fill="#0BC64B" /></svg>
                                        Custom measurement saved
                                    </div>
                                }
                            </div>
                        </Card>
                    </Grid.Col>
                    <Grid.Col lg={4} md={12} style={{height: "700px"}} className="mt-4 mt-md-4 mt-lg-0">
                        <Card className="h-100">
                            <Card.Header className=" d-flex justify-content-between">
                                <Card.Title>Measurement table</Card.Title>

                                <Card.Title className="d-flex align-items-center">
                                    cm 
                                    <Form.Switch
                                        name="unit"
                                        className="ml-2"
                                        checked={ unit === 'inches' }
                                        onChange={handleUnitChange}
                                    ></Form.Switch> 
                                    inches
                                </Card.Title>
                            </Card.Header>
                            <Table
                            cards={true}
                            striped={false}
                            responsive={true}
                            className="table card-table table-vcenter datatable h-100 overflow-auto"
                            >
                                <Table.Header>
                                    <Table.Row>
                                    <Table.ColHeader>Measurement</Table.ColHeader>
                                    <Table.ColHeader>Distance</Table.ColHeader>
                                    <Table.ColHeader>Confidence</Table.ColHeader>
                                    <Table.ColHeader />
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body style={{maxHeight: '700px'}} className="overflow-auto">
                                    {
                                        customMeasureValues?.map((m, key) => (
                                            <Table.Row key={key} className="custom-row">
                                                <Table.Col className="justify-between items-center">
                                                    {m.label}

                                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" className='cursor-pointer add-custom' 
                                                        onClick={() => {
                                                            if(tags.length > 0) setSelectedTag(tags[0].tag);
                                                            setHandleTagModalShow( key + 1 );
                                                        }}
                                                    >
                                                        <path d="M16.269 7.88446L8.89755 0.513053C8.76011 0.374711 8.59657 0.26503 8.41642 0.190372C8.23628 0.115713 8.0431 0.0775622 7.84809 0.0781313H0.968756C0.732548 0.0781313 0.506014 0.171965 0.338989 0.338989C0.171965 0.506014 0.0781313 0.732548 0.0781313 0.968756V7.84809C0.0775622 8.0431 0.115713 8.23628 0.190372 8.41642C0.26503 8.59657 0.374711 8.76011 0.513053 8.89755L7.88446 16.269C8.16282 16.5472 8.54031 16.7036 8.93391 16.7036C9.32752 16.7036 9.70501 16.5472 9.98337 16.269L16.269 9.98337C16.5472 9.70501 16.7036 9.32752 16.7036 8.93391C16.7036 8.54031 16.5472 8.16282 16.269 7.88446ZM8.93391 14.7994L1.85938 7.72266V1.85938H7.72266L14.7972 8.93391L8.93391 14.7994ZM5.71876 4.53126C5.71876 4.76612 5.64911 4.99571 5.51863 5.191C5.38814 5.38628 5.20268 5.53848 4.98569 5.62836C4.76871 5.71824 4.52994 5.74176 4.29959 5.69594C4.06923 5.65012 3.85764 5.53702 3.69157 5.37095C3.52549 5.20487 3.41239 4.99328 3.36657 4.76293C3.32075 4.53257 3.34427 4.29381 3.43415 4.07682C3.52403 3.85983 3.67623 3.67437 3.87152 3.54389C4.0668 3.4134 4.29639 3.34376 4.53126 3.34376C4.8462 3.34376 5.14825 3.46887 5.37095 3.69157C5.59364 3.91427 5.71876 4.21631 5.71876 4.53126Z" fill="#2E2E2E"/>
                                                    </svg>

                                                </Table.Col>
                                                <Table.Col>{m.distance}</Table.Col>
                                                <Table.Col>{m.confidence}</Table.Col>
                                                <Table.Col>
                                                    <a onClick={() => removeMeasure(key)} role='button' href='#'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7l16 0" /><path d="M10 11l0 6" /><path d="M14 11l0 6" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
                                                    </a>
                                                </Table.Col>
                                            </Table.Row>
                                        ))
                                    }

                                    {
                                        defaultMeasureValues?.map((m, key) => (
                                        <Table.Row key={key}>
                                            <Table.Col>{m.name}</Table.Col>
                                            <Table.Col>{m.distance}</Table.Col>
                                            <Table.Col>{m.confidence}</Table.Col>
                                            <Table.Col></Table.Col>
                                        </Table.Row>
                                        ))
                                    }

                                    {/* {
                                        appMeasureValues?.map((m, key) => (
                                        <Table.Row key={key}>
                                            <Table.Col>app_{m.name}</Table.Col>
                                            <Table.Col>{m.distance}</Table.Col>
                                            <Table.Col>{m.confidence}</Table.Col>
                                            <Table.Col></Table.Col>
                                        </Table.Row>
                                        ))
                                    } */}
                                </Table.Body>
                            </Table>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Col>
                        <Card className="mt-4 px-4 py-4">
                            <Card.Header>
                                <Card.Title >
                                    <p className="font-weight-bolder h4">Details</p>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body className="measuer-card-body">
                                <div>
                                    <label className=" text-secondary">PRODUCT</label>
                                    <label className="form-label">
                                        {
                                            measure?.product
                                        }
                                    </label>
                                </div>
                                <div>
                                    <label className=" text-secondary">SIDE ACTIVE</label>
                                    <label className="form-label">
                                        {
                                            measure?.sideBody ? 'Yes': 'No'
                                        }
                                    </label>
                                </div>
                                <div>
                                    <label className=" text-secondary">DATE</label>
                                    <label className="form-label">
                                        {
                                            convertDateFormat(measure?.date)
                                        }
                                    </label>
                                </div>
                                <div>
                                    <label className=" text-secondary">ID</label>
                                    <label className="form-label">
                                        {
                                            String(measure?.id).padStart(4, '0')
                                        }
                                    </label>
                                </div>
                                <div className='d-details'>
                                    {
                                        user?.type === ROLE_ADMIN && 
                                        <div className='mr-5'>
                                            <label className=" text-secondary">DEALER</label>
                                            <label className="form-label">
                                                {
                                                    `${String(measure?.user?.dealer?.id || "").padStart(4, '0')} - ${measure?.user?.dealer?.name || ""} ${measure?.user?.dealer?.last_name || ""}`
                                                }
                                            </label>
                                        </div>
                                    }
                                    <div className={``}>
                                        <label className=" text-secondary">USER EMAIL</label>
                                        <label className="form-label">
                                            {
                                                measure?.user?.email || ""
                                            }
                                        </label>
                                    </div>
                                    <div className={``}>
                                        <label className=" text-secondary">HEIGHT (CM)</label>
                                        <label className="form-label">
                                            {
                                                `${measure?.user?.height || ""} cm`
                                            }
                                        </label>
                                    </div>
                                    <div className={``}>
                                        <label className=" text-secondary">HEIGHT (FEET)</label>
                                        <label className="form-label">
                                            {
                                                `${measure?.user?.height_feet || ""} feet`
                                            }
                                        </label>
                                    </div>
                                    <div className={``}>
                                        <label className=" text-secondary">HEIGHT (INCHES)</label>
                                        <label className="form-label">
                                            {
                                                `${measure?.user?.height_inches || ""} inches`
                                            }
                                        </label>
                                    </div>
                                    <div className={``}>
                                        <label className=" text-secondary">WEIGHT (KG)</label>
                                        <label className="form-label">
                                            {
                                                `${measure?.user?.weight || ""} kg`
                                            }
                                        </label>
                                    </div>
                                    <div className={``}>
                                        <label className=" text-secondary">Condition</label>
                                        <label className="form-label">
                                            {
                                                `${measure?.user?.condition || ""}`
                                            }
                                        </label>
                                    </div>
                                </div>
                            </Card.Body>
                            {
                                user?.type === ROLE_ADMIN && 
                                <Card.Header>
                                    <div className='d-flex align-items-center'>
                                        <a href="#" className="btn btn-outline-danger d-flex align-items-center py-2" onClick={() => setHandleModalShow(true)}>Delete Measure</a>
                                        <label className=' text-danger mb-0 ml-5'>Note: This cannot be undone.</label>
                                    </div>
                                </Card.Header>
                            }
                        </Card>
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Col>
                        <Card className="mt-4 px-4 py-4">
                            <Card.Header>
                                <Card.Title >
                                    <p className="font-weight-bolder h4">Product Search</p>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body className="measuer-card-body">
                                <Grid.Row>
                                    <Grid.Col>
                                        <Form.Group >
                                            <Form.Input
                                                name="example-text-input"
                                                placeholder="Search for products"
                                                onChange={(e) => setSearch(e.target.value)}
                                                value={search}
                                                className="input-normal"
                                                icon="search"
                                                position="append"
                                            />
                                        </Form.Group>
                                    </Grid.Col>
                                </Grid.Row>
                                <Grid.Row>
                                    {
                                        products?.filter((p) => savedProducts.includes(p?.id))?.map((p, index) => (
                                            <Grid.Col xl={2} md={4} width={6} key={index} className="mb-4">
                                                <div className={`product-card-wrapper ${ savedProducts?.includes(p?.id) ? 'selected': '' }`} onClick={() => {
                                                    let productsIds = [];
                                                    if( savedProducts?.includes(p?.id) ) {
                                                        productsIds = savedProducts?.filter(sp => sp !== p?.id);
                                                    } else {
                                                        productsIds = [ ...savedProducts, p?.id ];
                                                    }
                                                    measureService
                                                        .updateMeasure(props.match.params.id, { products: JSON.stringify(productsIds) })
                                                        .then(res => {
                                                            const { data } = JSON.parse(res.data);
                                                            setSavedProducts( data?.products || [] );
                                                        });
                                                }} >
                                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 9.5C0 4.25329 4.25329 0 9.5 0C14.7467 0 19 4.25329 19 9.5C19 14.7467 14.7467 19 9.5 19C4.25329 19 0 14.7467 0 9.5Z" fill="#2B9A99"/>
                                                        <path d="M14.4088 6.96333L8.53432 12.8378C8.48315 12.8891 8.42235 12.9299 8.35541 12.9577C8.28847 12.9854 8.2167 12.9998 8.14422 12.9998C8.07173 12.9998 7.99996 12.9854 7.93302 12.9577C7.86608 12.9299 7.80528 12.8891 7.75412 12.8378L5.18405 10.2677C5.13282 10.2165 5.09218 10.1557 5.06446 10.0887C5.03673 10.0218 5.02246 9.95005 5.02246 9.8776C5.02246 9.80516 5.03673 9.73342 5.06446 9.66648C5.09218 9.59955 5.13282 9.53873 5.18405 9.4875C5.23527 9.43628 5.29609 9.39564 5.36302 9.36791C5.42996 9.34019 5.5017 9.32592 5.57415 9.32592C5.64659 9.32592 5.71833 9.34019 5.78527 9.36791C5.8522 9.39564 5.91302 9.43628 5.96425 9.4875L8.14467 11.6679L13.6295 6.18405C13.7329 6.08058 13.8733 6.02246 14.0196 6.02246C14.1659 6.02246 14.3062 6.08058 14.4097 6.18405C14.5131 6.28751 14.5713 6.42783 14.5713 6.57415C14.5713 6.72046 14.5131 6.86078 14.4097 6.96425L14.4088 6.96333Z" fill="white"/>
                                                    </svg>

                                                    <img src={ `${BACKENDURL}storage/${p?.images[0]}` } className="product-image" />
                                                    <div>
                                                        <div className='title'> { p?.product } </div>
                                                        <div className='content'> { p?.description } </div>
                                                    </div>
                                                </div>
                                            </Grid.Col>
                                        ))
                                    }
                                    {
                                        search &&
                                        products?.filter((p) => p?.product?.toLowerCase().includes(search?.toLowerCase()) && !savedProducts.includes(p?.id) )?.map((p, index) => (
                                            <Grid.Col xl={2} md={4} width={6} key={index} className="mb-4">
                                                <div className={`product-card-wrapper ${ savedProducts?.includes(p?.id) ? 'selected': '' }`} onClick={() => {
                                                    let productsIds = [];
                                                    if( savedProducts?.includes(p?.id) ) {
                                                        productsIds = savedProducts?.filter(sp => sp !== p?.id);
                                                    } else {
                                                        productsIds = [ ...savedProducts, p?.id ];
                                                    }
                                                    measureService
                                                        .updateMeasure(props.match.params.id, { products: JSON.stringify(productsIds) })
                                                        .then(res => {
                                                            const { data } = JSON.parse(res.data);
                                                            setSavedProducts( data?.products || [] );
                                                        });
                                                }} >
                                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 9.5C0 4.25329 4.25329 0 9.5 0C14.7467 0 19 4.25329 19 9.5C19 14.7467 14.7467 19 9.5 19C4.25329 19 0 14.7467 0 9.5Z" fill="#2B9A99"/>
                                                        <path d="M14.4088 6.96333L8.53432 12.8378C8.48315 12.8891 8.42235 12.9299 8.35541 12.9577C8.28847 12.9854 8.2167 12.9998 8.14422 12.9998C8.07173 12.9998 7.99996 12.9854 7.93302 12.9577C7.86608 12.9299 7.80528 12.8891 7.75412 12.8378L5.18405 10.2677C5.13282 10.2165 5.09218 10.1557 5.06446 10.0887C5.03673 10.0218 5.02246 9.95005 5.02246 9.8776C5.02246 9.80516 5.03673 9.73342 5.06446 9.66648C5.09218 9.59955 5.13282 9.53873 5.18405 9.4875C5.23527 9.43628 5.29609 9.39564 5.36302 9.36791C5.42996 9.34019 5.5017 9.32592 5.57415 9.32592C5.64659 9.32592 5.71833 9.34019 5.78527 9.36791C5.8522 9.39564 5.91302 9.43628 5.96425 9.4875L8.14467 11.6679L13.6295 6.18405C13.7329 6.08058 13.8733 6.02246 14.0196 6.02246C14.1659 6.02246 14.3062 6.08058 14.4097 6.18405C14.5131 6.28751 14.5713 6.42783 14.5713 6.57415C14.5713 6.72046 14.5131 6.86078 14.4097 6.96425L14.4088 6.96333Z" fill="white"/>
                                                    </svg>

                                                    <img src={ `${BACKENDURL}storage/${p?.images[0]}` } className="product-image" />
                                                    <div>
                                                        <div className='title'> { p?.product } </div>
                                                        <div className='content'> { p?.description } </div>
                                                    </div>
                                                </div>
                                            </Grid.Col>
                                        ))
                                    }
                                </Grid.Row>
                            </Card.Body>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>

            <Modal show={handleModalShow} centered={true} onHide={handleClose} dialogClassName="delete-user cancel-subscription" >
                <Modal.Body>
                    <div style={{ textAlign: "right", cursor: "pointer" }} className='close-icon' >
                        <svg width="21px" height="20px" viewBox="0 0 32 32" onClick={handleClose} >
                            <g stroke="#E5E9EF" strokeWidth="1" fill="none" fillRule="evenodd" >
                                <g transform="translate(-419.000000, -240.000000)" fill="#E5E9EF" >
                                    <g id="icons" transform="translate(56.000000, 160.000000)">
                                        <polygon points="375.0183 90 384 98.554 382.48065 100 373.5 91.446 364.5183 100 363 98.554 371.98065 90 363 81.446 364.5183 80 373.5 88.554 382.48065 80 384 81.446"></polygon>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div className="d-flex justify-content-center align-items-center notification-content">
                        <svg width="50px" height="50px" viewBox="0 0 50 50">
                        <g id="Desktop-App---BRANDED-V2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" >
                            <g id="Settings---Delete-User-Modal" transform="translate(-695.000000, -376.000000)" fill="#FF4747" >
                                <g id="Group-8" transform="translate(530.000000, 336.000000)" >
                                    <g id="Group-6" transform="translate(165.000000, 40.000000)" >
                                        <rect id="Rectangle" fillOpacity="0.1" x="0" y="0" width="50" height="50" rx="5" ></rect>
                                        <g id="delete" transform="translate(15.000000, 15.000000)" >
                                            <path d="M5,2 C5,0.89543 5.89543,0 7,0 L13,0 C14.1046,0 15,0.89543 15,2 L15,4 L16.9897,4 C16.9959,3.99994 17.0021,3.99994 17.0083,4 L19,4 C19.5523,4 20,4.44772 20,5 C20,5.55228 19.5523,6 19,6 L17.9311,6 L17.0638,18.1425 C16.989,19.1891 16.1182,20 15.0689,20 L4.93112,20 C3.88184,20 3.01096,19.1891 2.9362,18.1425 L2.06888,6 L1,6 C0.44772,6 0,5.55228 0,5 C0,4.44772 0.44772,4 1,4 L2.99174,4 C2.99795,3.99994 3.00414,3.99994 3.01032,4 L5,4 L5,2 Z M7,4 L13,4 L13,2 L7,2 L7,4 Z M4.07398,6 L4.93112,18 L15.0689,18 L15.926,6 L4.07398,6 Z M8,8 C8.5523,8 9,8.4477 9,9 L9,15 C9,15.5523 8.5523,16 8,16 C7.44772,16 7,15.5523 7,15 L7,9 C7,8.4477 7.44772,8 8,8 Z M12,8 C12.5523,8 13,8.4477 13,9 L13,15 C13,15.5523 12.5523,16 12,16 C11.4477,16 11,15.5523 11,15 L11,9 C11,8.4477 11.4477,8 12,8 Z" id="Shape" ></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                        </svg>
                        <div className="content-title">Delete Measure?</div>
                        <div className="content-note">
                            Are you sure? This cannot be undone.
                        </div>
                        <div className="d-flex user-page" style={{ marginTop: 20 }} >
                        <Button outline color="danger" onClick={deleteMeasure} >
                            Yes, Delete Measure
                        </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={!!handleTagModalShow} centered={true} onHide={() => setHandleTagModalShow(false)} dialogClassName="delete-user cancel-subscription add-tag" >
                <Modal.Body>
                    <div style={{ textAlign: "right", cursor: "pointer" }} className='close-icon' >
                        <svg width="21px" height="20px" viewBox="0 0 32 32" onClick={() => setHandleTagModalShow(false)} >
                            <g stroke="#E5E9EF" strokeWidth="1" fill="none" fillRule="evenodd" >
                                <g transform="translate(-419.000000, -240.000000)" fill="#E5E9EF" >
                                    <g id="icons" transform="translate(56.000000, 160.000000)">
                                        <polygon points="375.0183 90 384 98.554 382.48065 100 373.5 91.446 364.5183 100 363 98.554 371.98065 90 363 81.446 364.5183 80 373.5 88.554 382.48065 80 384 81.446"></polygon>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div className="d-flex justify-content-center align-items-center notification-content">
                        <svg width="50" height="51" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="0.5" width="50" height="50" rx="5" fill="#2B9A99" fillOpacity="0.1"/>
                            <g clipPath="url(#clip0_6308_1760)">
                                <path d="M35.1535 25.9452L26.6182 17.4099C26.4591 17.2497 26.2697 17.1227 26.0611 17.0362C25.8525 16.9498 25.6288 16.9056 25.4031 16.9063H17.4375C17.164 16.9063 16.9017 17.0149 16.7083 17.2083C16.5149 17.4017 16.4063 17.664 16.4063 17.9375V25.9031C16.4056 26.1288 16.4498 26.3525 16.5362 26.5611C16.6227 26.7697 16.7497 26.9591 16.9099 27.1182L25.4452 35.6535C25.7675 35.9757 26.2046 36.1567 26.6603 36.1567C27.1161 36.1567 27.5532 35.9757 27.8755 35.6535L35.1535 28.3755C35.4757 28.0532 35.6567 27.6161 35.6567 27.1603C35.6567 26.7046 35.4757 26.2675 35.1535 25.9452ZM26.6603 33.952L18.4688 25.7578V18.9688H25.2578L33.4494 27.1603L26.6603 33.952ZM22.9375 22.0625C22.9375 22.3345 22.8569 22.6003 22.7058 22.8264C22.5547 23.0525 22.3399 23.2288 22.0887 23.3328C21.8374 23.4369 21.561 23.4641 21.2943 23.4111C21.0275 23.358 20.7825 23.2271 20.5902 23.0348C20.3979 22.8425 20.267 22.5975 20.2139 22.3308C20.1609 22.064 20.1881 21.7876 20.2922 21.5363C20.3962 21.2851 20.5725 21.0703 20.7986 20.9192C21.0247 20.7681 21.2906 20.6875 21.5625 20.6875C21.9272 20.6875 22.2769 20.8324 22.5348 21.0902C22.7926 21.3481 22.9375 21.6978 22.9375 22.0625Z" fill="#2B9A99"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_6308_1760">
                                    <rect width="22" height="22" fill="white" transform="translate(14 14.5)"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <div className="content-title">Add Tag</div>
                        <div className="w-full">
                            <Form.Select
                                placeholder=""
                                onChange={(e) => {
                                    setSelectedTag(e.target.value)
                                }}
                                value={selectedTag}
                                className="input-normal"
                            >
                                {
                                    tags.map((tag, index) => {
                                        return (
                                            <option key={ index }>{ tag.tag }</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </div>
                        <div className="bottom" style={{ marginTop: 20 }} >
                            <Button outline color="secondary" onClick={() => setHandleTagModalShow(false)} >
                                Cancel
                            </Button>
                            <Button outline className="btn-add-tag" onClick={() => handleAddCustomTag()} >
                                Add Tag
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={handleAIProcessing} centered={true} onHide={handleClose} dialogClassName="delete-user cancel-subscription" >
                <Modal.Body>
                    <div className="d-flex justify-content-center align-items-center notification-content">
                        <MeasureSvg />

                        <div className="content-title">AI Processing Measure</div>
                        <div className="content-note">Our AI model is analysing this measure. It will be ready for you to review shortly.</div>
                        <div className="d-flex user-page" style={{ marginTop: 20 }} >
                        <Button 
                            outline 
                            className="btn-back-measure"
                            onClick={() => props.history.goBack()} 
                        >
                            Back to Measures
                        </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </SiteWrapper>
    )
}

export default ViewMeasure;



async function createDetector () {
    switch (STATE?.model) {
        case posedetection.SupportedModels.PoseNet:
          return posedetection.createDetector(STATE?.model, {
            quantBytes: 4,
            architecture: 'MobileNetV1',
            outputStride: 16,
            inputResolution: {width: 500, height: 500},
            multiplier: 0.75
          });
        case posedetection.SupportedModels.BlazePose:
          const runtime = STATE?.backend.split('-')[0];
          if (runtime === 'mediapipe') {
            return posedetection.createDetector(STATE?.model, {
              runtime,
              modelType: STATE?.modelConfig.type,
              solutionPath:
                  `https://cdn.jsdelivr.net/npm/@mediapipe/pose@${mpPose.VERSION}`,
              enableSegmentation: true,
              smoothSegmentation: true
            });
          } else if (runtime === 'tfjs') {
            return posedetection.createDetector(
                STATE?.model, {runtime, modelType: STATE?.modelConfig.type, enableSegmentation: true, smoothSegmentation: true});
          }
        case posedetection.SupportedModels.MoveNet:
          let modelType;
          if (STATE?.modelConfig.type == 'lightning') {
            modelType = posedetection.movenet.modelType.SINGLEPOSE_LIGHTNING;
          } else if (STATE?.modelConfig.type == 'thunder') {
            modelType = posedetection.movenet.modelType.SINGLEPOSE_THUNDER;
          } else if (STATE?.modelConfig.type == 'multipose') {
            modelType = posedetection.movenet.modelType.MULTIPOSE_LIGHTNING;
          }
          const modelConfig = {modelType};
    
          if (STATE?.modelConfig.customModel !== '') {
            modelConfig.modelUrl = STATE?.modelConfig.customModel;
          }
          if (STATE?.modelConfig.type === 'multipose') {
            modelConfig.enableTracking = STATE?.modelConfig.enableTracking;
          }
          return posedetection.createDetector(STATE?.model, modelConfig);
        case bodySegmentation.SupportedModels.MediaPipeSelfieSegmentation: {
            const runtime = STATE?.backend.split('-')[0];
            if (runtime === 'mediapipe') {
              return bodySegmentation.createSegmenter(STATE?.model, {
                runtime,
                modelType: STATE?.modelConfig.type,
                solutionPath:
                    `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation@${
                      mpSegmentation.VERSION}`
              });
            } else if (runtime === 'tfjs') {
              return bodySegmentation.createSegmenter(STATE?.model, {
                runtime,
                modelType: STATE?.modelConfig.type,
              });
            }
        }
    
    }
}

function getDistance3D(start, end, ratio, unit = "cm", measureConfigName) {
    var a = end.x - start.x;
    var b = end.y - start.y;
    var c = end.z - start.z;
    
    // console.log("ratio, a, b, c ===============> ", ratio, a, b, c);
    if( unit === "cm" ) {
        // console.log(`${ measureConfigName } => ${(Math.sqrt(a * a + b * b + c * c) * ratio).toFixed(2)} cm => ${(Math.sqrt(a * a + b * b + c * c)).toFixed(2)} px`);
        return (Math.sqrt(a * a + b * b + c * c) * ratio).toFixed(2) + " cm";
    } else if ( unit === "inches" ) {
        // console.log(`${ measureConfigName } => ${(Math.sqrt(a * a + b * b + c * c) * ratio / 2.54).toFixed(2)} inches => ${(Math.sqrt(a * a + b * b + c * c)).toFixed(2)} px`);
        return (Math.sqrt(a * a + b * b + c * c) * ratio / 2.54 ).toFixed(2) + "  inches";
    }
}

function getDistance3DNoUnit(start, end, ratio) {
    var a = end.x - start.x;
    var b = end.y - start.y;
    var c = end.z - start.z;
    
    return Math.sqrt(a * a + b * b + c * c) * ratio;
}

function calcMeasures (poses, measure, unit = "cm", ratio) {
    if(!poses || poses.length === 0) return;
    
    const keypoints3D = poses[0].keypoints;

    // console.log("### automatic measure values ###: ", poses);

    // const ratio = getRatio(poses[0].keypoints, poses[0].keypoints3D, measure?.sideBody, measure?.posture);


    const tempMeasures = [
        {
            ...measuresConfig[0], 
            distance: keypoints3D[11].score > 0.8 && keypoints3D[12].score > 0.8 ? getDistance3D(keypoints3D[11], keypoints3D[12], ratio, unit, measuresConfig[0].name): '0', 
            confidence: `${parseInt(keypoints3D[11].score * 100)} / ${parseInt(keypoints3D[12].score * 100)}`
        }, 
        {
            ...measuresConfig[1], 
            distance: keypoints3D[23].score > 0.8 && keypoints3D[24].score > 0.8 ? getDistance3D(keypoints3D[23], keypoints3D[24], ratio, unit, measuresConfig[1].name): '0', 
            confidence: `${parseInt(keypoints3D[23].score * 100)} / ${parseInt(keypoints3D[24].score * 100)}`
        }, 
        {
            ...measuresConfig[2], 
            distance: keypoints3D[5].score > 0.8 && keypoints3D[28].score > 0.8 ? getDistance3D(keypoints3D[5], keypoints3D[28], ratio, unit, measuresConfig[2].name): '0', 
            confidence: `${parseInt(keypoints3D[5].score * 100)} / ${parseInt(keypoints3D[28].score * 100)}`
        }, 
        {
            ...measuresConfig[3], 
            distance: keypoints3D[2].score > 0.8 && keypoints3D[27].score > 0.8 ? getDistance3D(keypoints3D[2], keypoints3D[27], ratio, unit, measuresConfig[3].name): '0', 
            confidence: `${parseInt(keypoints3D[2].score * 100)} / ${parseInt(keypoints3D[27].score * 100)}`
        }, 
        {
            ...measuresConfig[4], 
            distance: keypoints3D[26].score > 0.8 && keypoints3D[28].score > 0.8 ? getDistance3D(keypoints3D[26], keypoints3D[28], ratio, unit, measuresConfig[4].name): '0', 
            confidence: `${parseInt(keypoints3D[26].score * 100)} / ${parseInt(keypoints3D[28].score * 100)}`
        }, 
        {
            ...measuresConfig[5], 
            distance: keypoints3D[25].score > 0.8 && keypoints3D[27].score > 0.8 ? getDistance3D(keypoints3D[25], keypoints3D[27], ratio, unit, measuresConfig[5].name): '0', 
            confidence: `${parseInt(keypoints3D[25].score * 100)} / ${parseInt(keypoints3D[27].score * 100)}`
        }, 
        {
            ...measuresConfig[6], 
            distance: keypoints3D[24].score > 0.8 && keypoints3D[26].score > 0.8 ? getDistance3D(keypoints3D[24], keypoints3D[26], ratio, unit, measuresConfig[6].name): '0', 
            confidence: `${parseInt(keypoints3D[24].score * 100)} / ${parseInt(keypoints3D[26].score * 100)}`
        }, 
        {
            ...measuresConfig[7], 
            distance: keypoints3D[23].score > 0.8 && keypoints3D[25].score > 0.8 ? getDistance3D(keypoints3D[23], keypoints3D[25], ratio, unit, measuresConfig[7].name): '0', 
            confidence: `${parseInt(keypoints3D[23].score * 100)} / ${parseInt(keypoints3D[25].score * 100)}`
        }, 
        {
            ...measuresConfig[8], 
            distance: keypoints3D[12].score > 0.8 && keypoints3D[24].score > 0.8 ? getDistance3D(keypoints3D[12], keypoints3D[24], ratio, unit, measuresConfig[8].name): '0', 
            confidence: `${parseInt(keypoints3D[12].score * 100)} / ${parseInt(keypoints3D[24].score * 100)}`
        }, 
        {
            ...measuresConfig[9], 
            distance: keypoints3D[11].score > 0.8 && keypoints3D[23].score > 0.8 ? getDistance3D(keypoints3D[11], keypoints3D[23], ratio, unit, measuresConfig[9].name): '0', 
            confidence: `${parseInt(keypoints3D[11].score * 100)} / ${parseInt(keypoints3D[23].score * 100)}`
        }, 
        {
            ...measuresConfig[10], 
            distance: keypoints3D[5].score > 0.8 && keypoints3D[24].score > 0.8 ? getDistance3D(keypoints3D[5], keypoints3D[24], ratio, unit, measuresConfig[10].name): '0', 
            confidence: `${parseInt(keypoints3D[5].score * 100)} / ${parseInt(keypoints3D[24].score * 100)}`
        }, 
        {
            ...measuresConfig[11], 
            distance: keypoints3D[2].score > 0.8 && keypoints3D[23].score > 0.8 ? getDistance3D(keypoints3D[2], keypoints3D[23], ratio, unit, measuresConfig[11].name): '0', 
            confidence: `${parseInt(keypoints3D[2].score * 100)} / ${parseInt(keypoints3D[23].score * 100)}`
        },
        {
            ...measuresConfig[12], 
            distance: keypoints3D[14].score > 0.8 && keypoints3D[16].score > 0.8 ? getDistance3D(keypoints3D[14], keypoints3D[16], ratio, unit, measuresConfig[12].name): '0', 
            confidence: `${parseInt(keypoints3D[14].score * 100)} / ${parseInt(keypoints3D[16].score * 100)}`
        }, 
        {
            ...measuresConfig[13], 
            distance: keypoints3D[13].score > 0.8 && keypoints3D[15].score > 0.8 ? getDistance3D(keypoints3D[13], keypoints3D[15], ratio, unit, measuresConfig[13].name): '0', 
            confidence: `${parseInt(keypoints3D[13].score * 100)} / ${parseInt(keypoints3D[15].score * 100)}`
        }
    ];

    return tempMeasures;
}

function calcDatabaseMeasures (poses, ratio) {
    if(!poses || poses.length === 0) return;
    
    const keypoints3D = poses[0].keypoints;
    const unit = "cm";

    const tempMeasures = {
        [measuresConfig[0].name]: {
            ...measuresConfig[0], 
            distance: keypoints3D[11].score > 0.8 && keypoints3D[12].score > 0.8 ? getDistance3DNoUnit(keypoints3D[11], keypoints3D[12], ratio): '0', 
            confidence: parseInt(keypoints3D[12].score * 100)
        }, 
        [measuresConfig[1].name]: {
            ...measuresConfig[1], 
            distance: keypoints3D[23].score > 0.8 && keypoints3D[24].score > 0.8 ? getDistance3DNoUnit(keypoints3D[23], keypoints3D[24], ratio): '0', 
            confidence: parseInt(keypoints3D[24].score * 100)
        }, 
        [measuresConfig[2].name]: {
            ...measuresConfig[2], 
            distance: keypoints3D[5].score > 0.8 && keypoints3D[28].score > 0.8 ? getDistance3DNoUnit(keypoints3D[5], keypoints3D[28], ratio): '0', 
            confidence: parseInt(keypoints3D[28].score * 100)
        }, 
        [measuresConfig[3].name]: {
            ...measuresConfig[3], 
            distance: keypoints3D[2].score > 0.8 && keypoints3D[27].score > 0.8 ? getDistance3DNoUnit(keypoints3D[2], keypoints3D[27], ratio): '0', 
            confidence: parseInt(keypoints3D[27].score * 100)
        }, 
        [measuresConfig[4].name]: {
            ...measuresConfig[4], 
            distance: keypoints3D[26].score > 0.8 && keypoints3D[28].score > 0.8 ? getDistance3DNoUnit(keypoints3D[26], keypoints3D[28], ratio): '0', 
            confidence: parseInt(keypoints3D[28].score * 100)
        }, 
        [measuresConfig[5].name]: {
            ...measuresConfig[5], 
            distance: keypoints3D[25].score > 0.8 && keypoints3D[27].score > 0.8 ? getDistance3DNoUnit(keypoints3D[25], keypoints3D[27], ratio): '0', 
            confidence: parseInt(keypoints3D[27].score * 100)
        }, 
        [measuresConfig[6].name]: {
            ...measuresConfig[6], 
            distance: keypoints3D[24].score > 0.8 && keypoints3D[26].score > 0.8 ? getDistance3DNoUnit(keypoints3D[24], keypoints3D[26], ratio): '0', 
            confidence: parseInt(keypoints3D[24].score * 100)
        }, 
        [measuresConfig[7].name]: {
            ...measuresConfig[7], 
            distance: keypoints3D[23].score > 0.8 && keypoints3D[25].score > 0.8 ? getDistance3DNoUnit(keypoints3D[23], keypoints3D[25], ratio): '0', 
            confidence: parseInt(keypoints3D[25].score * 100)
        }, 
        [measuresConfig[8].name]: {
            ...measuresConfig[8], 
            distance: keypoints3D[12].score > 0.8 && keypoints3D[24].score > 0.8 ? getDistance3DNoUnit(keypoints3D[12], keypoints3D[24], ratio): '0', 
            confidence: parseInt(keypoints3D[24].score * 100)
        }, 
        [measuresConfig[9].name]: {
            ...measuresConfig[9], 
            distance: keypoints3D[11].score > 0.8 && keypoints3D[23].score > 0.8 ? getDistance3DNoUnit(keypoints3D[11], keypoints3D[23], ratio): '0', 
            confidence: parseInt(keypoints3D[23].score * 100)
        }, 
        [measuresConfig[10].name]: {
            ...measuresConfig[10], 
            distance: keypoints3D[5].score > 0.8 && keypoints3D[24].score > 0.8 ? getDistance3DNoUnit(keypoints3D[5], keypoints3D[24], ratio): '0', 
            confidence: parseInt(keypoints3D[5].score * 100)
        }, 
        [measuresConfig[11].name]: {
            ...measuresConfig[11], 
            distance: keypoints3D[2].score > 0.8 && keypoints3D[23].score > 0.8 ? getDistance3DNoUnit(keypoints3D[2], keypoints3D[23], ratio): '0', 
            confidence: parseInt(keypoints3D[2].score * 100)
        },
        [measuresConfig[12].name]: {
            ...measuresConfig[12], 
            distance: keypoints3D[14].score > 0.8 && keypoints3D[16].score > 0.8 ? getDistance3DNoUnit(keypoints3D[14], keypoints3D[16], ratio): '0', 
            confidence: parseInt(keypoints3D[14].score * 100)
        }, 
        [measuresConfig[13].name]: {
            ...measuresConfig[13], 
            distance: keypoints3D[13].score > 0.8 && keypoints3D[15].score > 0.8 ? getDistance3DNoUnit(keypoints3D[13], keypoints3D[15], ratio): '0', 
            confidence: parseInt(keypoints3D[13].score * 100)
        }
    };

    return tempMeasures;
}

function trackTransforms(ctx) {
    var svg = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
    var xform = svg.createSVGMatrix();
    ctx.getTransform = function () { return xform; };

    var savedTransforms = [];
    var save = ctx.save;
    ctx.save = function () {
        savedTransforms.push(xform.translate(0, 0));
        return save.call(ctx);
    };

    var restore = ctx.restore;
    ctx.restore = function () {
        xform = savedTransforms.pop();
        return restore.call(ctx);
    };

    var scale = ctx.scale;
    ctx.scale = function (sx, sy) {
        xform = xform.scaleNonUniform(sx, sy);
        return scale.call(ctx, sx, sy);
    };

    var rotate = ctx.rotate;
    ctx.rotate = function (radians) {
        xform = xform.rotate(radians * 180 / Math.PI);
        return rotate.call(ctx, radians);
    };

    var translate = ctx.translate;
    ctx.translate = function (dx, dy) {
        xform = xform.translate(dx, dy);
        return translate.call(ctx, dx, dy);
    };

    var transform = ctx.transform;
    ctx.transform = function (a, b, c, d, e, f) {
        var m2 = svg.createSVGMatrix();
        m2.a = a; m2.b = b; m2.c = c; m2.d = d; m2.e = e; m2.f = f;
        xform = xform.multiply(m2);
        return transform.call(ctx, a, b, c, d, e, f);
    };

    var setTransform = ctx.setTransform;
    ctx.setTransform = function (a, b, c, d, e, f) {
        xform.a = a;
        xform.b = b;
        xform.c = c;
        xform.d = d;
        xform.e = e;
        xform.f = f;
        return setTransform.call(ctx, a, b, c, d, e, f);
    };

    var pt = svg.createSVGPoint();
    ctx.transformedPoint = function (x, y) {
        pt.x = x; pt.y = y;
        return pt.matrixTransform(xform.inverse());
    }
}

function distancePointToLine(x, y, x1, y1, x2, y2) {
    // Calculate the slope of the line
    var m = (y2 - y1) / (x2 - x1);

    // Calculate the y-intercept of the line
    var b = y1 - m * x1;

    // Calculate the perpendicular distance from the point to the line
    var distance = Math.abs((m * x - y + b) / Math.sqrt(m * m + 1));

    return distance;
}






function getRealDistance3D(start, end, unit = "cm", measureConfigName) {
    var a = end.x - start.x;
    var b = end.y - start.y;
    var c = end.z - start.z;
    
    if( unit === "cm" ) {
        return (Math.sqrt(a * a + b * b + c * c) * 100).toFixed(2) + " cm";
    } else if ( unit === "inches" ) {
        return (Math.sqrt(a * a + b * b + c * c) * 100 / 2.54 ).toFixed(2) + "  inches";
    }
}

function calcRealMeasures (poses, measure, unit = "cm") {
    if(!poses || poses.length === 0) return;

    const keypoints3D = poses[0].keypoints3D;

    const tempMeasures = [
        {
            ...measuresConfig[0], 
            distance: keypoints3D[11].score > 0.8 && keypoints3D[12].score > 0.8 ? getRealDistance3D(keypoints3D[11], keypoints3D[12], unit, measuresConfig[0].name): '0', 
            confidence: `${parseInt(keypoints3D[11].score * 100)} / ${parseInt(keypoints3D[12].score * 100)}`
        }, 
        {
            ...measuresConfig[1], 
            distance: keypoints3D[23].score > 0.8 && keypoints3D[24].score > 0.8 ? getRealDistance3D(keypoints3D[23], keypoints3D[24], unit, measuresConfig[1].name): '0', 
            confidence: `${parseInt(keypoints3D[23].score * 100)} / ${parseInt(keypoints3D[24].score * 100)}`
        }, 
        {
            ...measuresConfig[2], 
            distance: keypoints3D[5].score > 0.8 && keypoints3D[28].score > 0.8 ? getRealDistance3D(keypoints3D[5], keypoints3D[28], unit, measuresConfig[2].name): '0', 
            confidence: `${parseInt(keypoints3D[5].score * 100)} / ${parseInt(keypoints3D[28].score * 100)}`
        }, 
        {
            ...measuresConfig[3], 
            distance: keypoints3D[2].score > 0.8 && keypoints3D[27].score > 0.8 ? getRealDistance3D(keypoints3D[2], keypoints3D[27], unit, measuresConfig[3].name): '0', 
            confidence: `${parseInt(keypoints3D[2].score * 100)} / ${parseInt(keypoints3D[27].score * 100)}`
        }, 
        {
            ...measuresConfig[4], 
            distance: keypoints3D[26].score > 0.8 && keypoints3D[28].score > 0.8 ? getRealDistance3D(keypoints3D[26], keypoints3D[28], unit, measuresConfig[4].name): '0', 
            confidence: `${parseInt(keypoints3D[26].score * 100)} / ${parseInt(keypoints3D[28].score * 100)}`
        }, 
        {
            ...measuresConfig[5], 
            distance: keypoints3D[25].score > 0.8 && keypoints3D[27].score > 0.8 ? getRealDistance3D(keypoints3D[25], keypoints3D[27], unit, measuresConfig[5].name): '0', 
            confidence: `${parseInt(keypoints3D[25].score * 100)} / ${parseInt(keypoints3D[27].score * 100)}`
        }, 
        {
            ...measuresConfig[6], 
            distance: keypoints3D[24].score > 0.8 && keypoints3D[26].score > 0.8 ? getRealDistance3D(keypoints3D[24], keypoints3D[26], unit, measuresConfig[6].name): '0', 
            confidence: `${parseInt(keypoints3D[24].score * 100)} / ${parseInt(keypoints3D[26].score * 100)}`
        }, 
        {
            ...measuresConfig[7], 
            distance: keypoints3D[23].score > 0.8 && keypoints3D[25].score > 0.8 ? getRealDistance3D(keypoints3D[23], keypoints3D[25], unit, measuresConfig[7].name): '0', 
            confidence: `${parseInt(keypoints3D[23].score * 100)} / ${parseInt(keypoints3D[25].score * 100)}`
        }, 
        {
            ...measuresConfig[8], 
            distance: keypoints3D[12].score > 0.8 && keypoints3D[24].score > 0.8 ? getRealDistance3D(keypoints3D[12], keypoints3D[24], unit, measuresConfig[8].name): '0', 
            confidence: `${parseInt(keypoints3D[12].score * 100)} / ${parseInt(keypoints3D[24].score * 100)}`
        }, 
        {
            ...measuresConfig[9], 
            distance: keypoints3D[11].score > 0.8 && keypoints3D[23].score > 0.8 ? getRealDistance3D(keypoints3D[11], keypoints3D[23], unit, measuresConfig[9].name): '0', 
            confidence: `${parseInt(keypoints3D[11].score * 100)} / ${parseInt(keypoints3D[23].score * 100)}`
        }, 
        {
            ...measuresConfig[10], 
            distance: keypoints3D[5].score > 0.8 && keypoints3D[24].score > 0.8 ? getRealDistance3D(keypoints3D[5], keypoints3D[24], unit, measuresConfig[10].name): '0', 
            confidence: `${parseInt(keypoints3D[5].score * 100)} / ${parseInt(keypoints3D[24].score * 100)}`
        }, 
        {
            ...measuresConfig[11], 
            distance: keypoints3D[2].score > 0.8 && keypoints3D[23].score > 0.8 ? getRealDistance3D(keypoints3D[2], keypoints3D[23], unit, measuresConfig[11].name): '0', 
            confidence: `${parseInt(keypoints3D[2].score * 100)} / ${parseInt(keypoints3D[23].score * 100)}`
        },
        {
            ...measuresConfig[12], 
            distance: keypoints3D[14].score > 0.8 && keypoints3D[16].score > 0.8 ? getRealDistance3D(keypoints3D[14], keypoints3D[16], unit, measuresConfig[12].name): '0', 
            confidence: `${parseInt(keypoints3D[14].score * 100)} / ${parseInt(keypoints3D[16].score * 100)}`
        }, 
        {
            ...measuresConfig[13], 
            distance: keypoints3D[13].score > 0.8 && keypoints3D[15].score > 0.8 ? getRealDistance3D(keypoints3D[13], keypoints3D[15], unit, measuresConfig[13].name): '0', 
            confidence: `${parseInt(keypoints3D[13].score * 100)} / ${parseInt(keypoints3D[15].score * 100)}`
        }
    ];

    return tempMeasures;
}

function calcAppMeasures (poses, measure, appMeasures, unit = "cm", ratio) {
    if(!poses || poses.length === 0) return;
    
    // const keypoints3D = poses[0].keypoints;

    // const ratio = getRatio(poses[0].keypoints, poses[0].keypoints3D, measure?.sideBody, measure?.posture);

    // console.log("appMeasures ##########", appMeasures)
    const tempMeasures = [
        {
            ...measuresConfig[0], 
            distance: appMeasures?.leftShoulder?.accuracy > 0.8 && appMeasures?.rightShoulder?.accuracy > 0.8 ? getDistance3D(appMeasures?.leftShoulder, appMeasures?.rightShoulder, ratio, unit, `app_${measuresConfig[0].name}`): '0', 
            confidence: parseInt(appMeasures?.rightShoulder?.accuracy * 100)
        }, 
        {
            ...measuresConfig[1], 
            distance: appMeasures?.leftHip?.accuracy > 0.8 && appMeasures?.rightHip?.accuracy > 0.8 ? getDistance3D(appMeasures?.leftHip, appMeasures?.rightHip, ratio, unit, `app_${measuresConfig[1].name}`): '0', 
            confidence: parseInt(appMeasures?.rightHip?.accuracy * 100)
        }, 
        {
            ...measuresConfig[2], 
            distance: appMeasures?.rightEye?.accuracy > 0.8 && appMeasures?.rightAnkle?.accuracy > 0.8 ? getDistance3D(appMeasures?.rightEye, appMeasures?.rightAnkle, ratio, unit, `app_${measuresConfig[2].name}`): '0', 
            confidence: parseInt(appMeasures?.rightAnkle?.accuracy * 100)
        }, 
        {
            ...measuresConfig[3], 
            distance: appMeasures?.leftEye?.accuracy > 0.8 && appMeasures?.leftAnkle?.accuracy > 0.8 ? getDistance3D(appMeasures?.leftEye, appMeasures?.leftAnkle, ratio, unit, `app_${measuresConfig[3].name}`): '0', 
            confidence: parseInt(appMeasures?.leftAnkle?.accuracy * 100)
        }, 
        {
            ...measuresConfig[4], 
            distance: appMeasures?.rightKnee?.accuracy > 0.8 && appMeasures?.rightAnkle?.accuracy > 0.8 ? getDistance3D(appMeasures?.rightKnee, appMeasures?.rightAnkle, ratio, unit, `app_${measuresConfig[4].name}`): '0', 
            confidence: parseInt(appMeasures?.rightAnkle?.accuracy * 100)
        }, 
        {
            ...measuresConfig[5], 
            distance: appMeasures?.leftKnee?.accuracy > 0.8 && appMeasures?.leftAnkle?.accuracy > 0.8 ? getDistance3D(appMeasures?.leftKnee, appMeasures?.leftAnkle, ratio, unit, `app_${measuresConfig[5].name}`): '0', 
            confidence: parseInt(appMeasures?.leftAnkle?.accuracy * 100)
        }, 
        {
            ...measuresConfig[6], 
            distance: appMeasures?.rightHip?.accuracy > 0.8 && appMeasures?.rightKnee?.accuracy > 0.8 ? getDistance3D(appMeasures?.rightHip, appMeasures?.rightKnee, ratio, unit, `app_${measuresConfig[6].name}`): '0', 
            confidence: parseInt(appMeasures?.rightHip?.accuracy * 100)
        }, 
        {
            ...measuresConfig[7], 
            distance: appMeasures?.leftHip?.accuracy > 0.8 && appMeasures?.leftKnee?.accuracy > 0.8 ? getDistance3D(appMeasures?.leftHip, appMeasures?.leftKnee, ratio, unit, `app_${measuresConfig[7].name}`): '0', 
            confidence: parseInt(appMeasures?.leftKnee?.accuracy * 100)
        }, 
        {
            ...measuresConfig[8], 
            distance: appMeasures?.rightShoulder?.accuracy > 0.8 && appMeasures?.rightHip?.accuracy > 0.8 ? getDistance3D(appMeasures?.rightShoulder, appMeasures?.rightHip, ratio, unit, `app_${measuresConfig[8].name}`): '0', 
            confidence: parseInt(appMeasures?.rightHip?.accuracy * 100)
        }, 
        {
            ...measuresConfig[9], 
            distance: appMeasures?.leftShoulder?.accuracy > 0.8 && appMeasures?.leftHip?.accuracy > 0.8 ? getDistance3D(appMeasures?.leftShoulder, appMeasures?.leftHip, ratio, unit, `app_${measuresConfig[9].name}`): '0', 
            confidence: parseInt(appMeasures?.leftHip?.accuracy * 100)
        }, 
        {
            ...measuresConfig[10], 
            distance: appMeasures?.rightEye?.accuracy > 0.8 && appMeasures?.rightHip?.accuracy > 0.8 ? getDistance3D(appMeasures?.rightEye, appMeasures?.rightHip, ratio, unit, `app_${measuresConfig[10].name}`): '0', 
            confidence: parseInt(appMeasures?.rightEye?.accuracy * 100)
        }, 
        {
            ...measuresConfig[11], 
            distance: appMeasures?.leftEye?.accuracy > 0.8 && appMeasures?.leftHip?.accuracy > 0.8 ? getDistance3D(appMeasures?.leftEye, appMeasures?.leftHip, ratio, unit, `app_${measuresConfig[11].name}`): '0', 
            confidence: parseInt(appMeasures?.leftEye?.accuracy * 100)
        },
        {
            ...measuresConfig[12], 
            distance: appMeasures?.rightElbow?.accuracy > 0.8 && appMeasures?.rightWrist?.accuracy > 0.8 ? getDistance3D(appMeasures?.rightElbow, appMeasures?.rightWrist, ratio, unit, `app_${measuresConfig[12].name}`): '0', 
            confidence: parseInt(appMeasures?.rightElbow?.accuracy * 100)
        }, 
        {
            ...measuresConfig[13], 
            distance: appMeasures?.leftElbow?.accuracy > 0.8 && appMeasures?.leftWrist?.accuracy > 0.8 ? getDistance3D(appMeasures?.leftElbow, appMeasures?.leftWrist, ratio, unit, `app_${measuresConfig[13].name}`): '0', 
            confidence: parseInt(appMeasures?.leftElbow?.accuracy * 100)
        }
    ];

    return tempMeasures;
}

function calculateMedian(arr) {
    if (arr.length === 0) return null; // Handle empty array

    // Sort the array in ascending order
    arr.sort((a, b) => a - b);

    const middleIndex = Math.floor(arr.length / 2);

    if (arr.length % 2 === 0) {
        // If even, average the two middle elements
        return (arr[middleIndex - 1] + arr[middleIndex]) / 2;
    } else {
        // If odd, return the middle element
        return arr[middleIndex];
    }
}

function calculateMedianWithOriginalIndex(arr) {
    if (arr.length === 0) return null; // Handle empty array

    // Create an array of objects, each containing the value and its original index
    const indexedArr = arr.map((value, index) => ({ value, index }));

    // Sort the array by value
    indexedArr.sort((a, b) => a.value - b.value);

    const middleIndex = Math.floor(indexedArr.length / 2);

    let medianValue, originalIndex;

    if (indexedArr.length % 2 === 0) {
        // If even, calculate the median value as the average of the two middle values
        medianValue = (indexedArr[middleIndex - 1].value + indexedArr[middleIndex].value) / 2;

        // For even-length arrays, both middle indices could be considered, so return both indices
        // originalIndex = [indexedArr[middleIndex - 1].index, indexedArr[middleIndex].index];
        originalIndex = indexedArr[middleIndex - 1].index;
    } else {
        // If odd, the median value is the middle element
        medianValue = indexedArr[middleIndex].value;
        originalIndex = indexedArr[middleIndex].index;
    }

    return { medianValue, index: originalIndex };
}