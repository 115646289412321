// @flow

import React, { useState } from "react";
import { Formik, Field } from "formik";
import { Page, Grid, Form, Alert } from "tabler-react";
import { NavLink } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import PageFooter from "./PageFooter";
import * as authService from "../store/services/authServices";
import { authLogin } from "../store/actions/authActions";
import Loading from "react-loading";
import { useHistory } from "react-router-dom";

const FirstLoginSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
  password_confirmation: Yup.string().oneOf([Yup.ref("password"), null], "Password must match"),
});

const SecondLoginSchema = Yup.object().shape({
  address_1: Yup.string().required("Address Line 1 is required."), 
  city: Yup.string().required("City is required."), 
  postcode: Yup.string().required("Postcode is required."), 
  company_name: Yup.string().required("Company Name is required."), 
});


const SuccessPage = () => {
  const history = useHistory();
  const goToLogin = () => {
    history.push("/login");
  };
  return (
    <Page>
      <div className='login-page'>
        <Grid.Row>
          <div className='col-lg-6'>
            <Grid.Row>
              <div className='col-md-6 col-lg-8 form-body' style={{ marginTop: "auto" }}>
                <label className='welcome-title'>Thanks for registering</label>
                <label className='label-title'> We need to verify your email address before you can login. Check your inbox for an email from us which has instructions on what to do next.</label>
                {/* <label className='label-title'>Your account has been registered successfully!</label> */}
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    goToLogin();
                  }}
                  autoComplete='off'
                >
                  <button type='submit' className='btn btn-block btn-barlows'>
                    Back to Log In
                  </button>
                </form>
              </div>
            </Grid.Row>
          </div>
          <div className='col-lg-6 '>
            <div className='ev-logo password-logo'></div>
          </div>
        </Grid.Row>
        <PageFooter />
      </div>
    </Page>
  );
};


function RegisterPage(props) {
  const [isLoading, setSubmit] = useState(false);
  const dispatch = useDispatch();
  const [alert, setAlert] = useState({});
  const [isSuccess, setSuccess] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleRegister = (data) => {
    setSubmit(true);
    authService
      .register(data)
      .then((res) => {
        setSubmit(false);
        setSuccess(true);
      })
      .catch((err) => {
        setAlert({
          type: "danger",
          message: (err.response && err.response.data.message) || err.message,
        });
        setSubmit(false);
      });
  };
  return isSuccess ? (
    <SuccessPage />
  ) : (
    <Formik
      initialValues={{
        name: "",
        email: "",
        password: "",
        password_confirmation: "",

        address_1: "", 
        address_2: "", 
        city: "", 
        postcode: "", 
        company_name: "", 
        type: "Dealer",
      }}
      validationSchema={activeIndex === 0 ? FirstLoginSchema : SecondLoginSchema}
      onSubmit={(values, { setSubmitting, setErrors /* setValues and other goodies */ }) => {
        if (values.name.split(" ").length > 1) {
          values.type = "Dealer";
          if( activeIndex === 0 ) {
            setActiveIndex(1)
          } else {
            console.log(values);
            // handleRegister(values);
          }
        } else {
          setErrors({ name: "Please enter full name." });
        }
      }}
      render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <div className='page'>
          {isLoading && (
            <div className='loading'>
              <Loading type='spinningBubbles' color='#171717' />
            </div>
          )}
          <Page>
            <div className='login-page'>
              <Grid.Row>
                <div className='col-lg-6 form-wrapper'>
                  <Grid.Row>
                    <div className='col-md-6 col-lg-8 form-body'>
                      <label className='welcome-title'>Create Account</label>
                      <label className='label-title'>Register by filling out the details below.</label>
                      <form onSubmit={handleSubmit} autoComplete='off'>
                        {
                          activeIndex === 0 && (
                            <div>
                              <div className='form-group'>
                                <Field
                                  name='name'
                                  className={errors.name && touched.name ? "form-control is-invalid state-invalid" : "form-control"}
                                  placeholder='Name'
                                />
                                {errors.name && touched.name && <div className='invalid-feedback'>{errors.name}</div>}
                              </div>
                              <div className='form-group'>
                                <Field
                                  name='email'
                                  className={errors.email && touched.email ? "form-control is-invalid state-invalid" : "form-control"}
                                  placeholder='Email Address'
                                />
                                {errors.email && touched.email && <div className='invalid-feedback'>{errors.email}</div>}
                              </div>
                              <div className='form-group'>
                                <Field
                                  type='password'
                                  name='password'
                                  className={errors.password && touched.password ? "form-control is-invalid state-invalid" : "form-control"}
                                  placeholder='Password'
                                />
                                {errors.password && touched.password && <div className='invalid-feedback'>{errors.password}</div>}
                              </div>
                              <div className='form-group'>
                                <Field
                                  type='password'
                                  name='password_confirmation'
                                  className={
                                    errors.password_confirmation && touched.password_confirmation ? "form-control is-invalid state-invalid" : "form-control"
                                  }
                                  placeholder='Repeat Password'
                                />
                                {errors.password_confirmation && touched.password_confirmation && (
                                  <div className='invalid-feedback'>{errors.password_confirmation}</div>
                                )}
                              </div>
                            </div>
                          )
                        }
                        {
                          activeIndex === 1 && (
                            <div>
                              <div className='form-group'>
                                <Field
                                  name='address_1'
                                  className={errors.address_1 && touched.address_1 ? "form-control is-invalid state-invalid" : "form-control"}
                                  placeholder='Address Line 1'
                                />
                                {errors.address_1 && touched.address_1 && <div className='invalid-feedback'>{errors.address_1}</div>}
                              </div>
                              <div className='form-group'>
                                <Field
                                  name='address_2'
                                  className={errors.address_2 && touched.address_2 ? "form-control is-invalid state-invalid" : "form-control"}
                                  placeholder='Address Line 2'
                                />
                                {errors.address_2 && touched.address_2 && <div className='invalid-feedback'>{errors.address_2}</div>}
                              </div>
                              <div className="flex gap-30">
                                <div className='form-group w-full'>
                                  <Field
                                    name='city'
                                    className={errors.city && touched.city ? "form-control is-invalid state-invalid" : "form-control"}
                                    placeholder='City'
                                  />
                                  {errors.city && touched.city && <div className='invalid-feedback'>{errors.city}</div>}
                                </div>
                                <div className='form-group w-full'>
                                  <Field
                                    name='postcode'
                                    className={errors.postcode && touched.postcode ? "form-control is-invalid state-invalid" : "form-control"}
                                    placeholder='Postcode'
                                  />
                                  {errors.postcode && touched.postcode && <div className='invalid-feedback'>{errors.postcode}</div>}
                                </div>
                              </div>
                              <div className='form-group'>
                                <Field
                                  name='company_name'
                                  className={errors.company_name && touched.company_name ? "form-control is-invalid state-invalid" : "form-control"}
                                  placeholder='Company Name'
                                />
                                {errors.company_name && touched.company_name && <div className='invalid-feedback'>{errors.company_name}</div>}
                              </div>
                            </div>
                          )
                        }

                        {alert.message && <Alert type={alert.type}>{alert.message}</Alert>}
                        <div className="flex gap-30">
                          <div className="dot-container">
                            <span className={`dot ${ activeIndex === 0 ? 'active': '' }`}></span>
                            <span className={`dot ${ activeIndex === 1 ? 'active': '' }`}></span>
                          </div>
                          <button type="submit" className='btn btn-block btn-barlows ' disabled={isLoading}>
                              {
                                activeIndex === 0 ?
                                'Next'
                                :
                                'Register'
                              }
                          </button>
                        </div>
                      </form>
                      <span className='flex'>
                        <label className='label-title'>Already have account? &nbsp;</label>
                        <NavLink className='nav-link' to='/login'>
                          Log In
                        </NavLink>
                      </span>
                      {/* <NavLink className='nav-link' to='/forgot-password'>
                      Forgot Password?
                    </NavLink> */}
                    </div>
                  </Grid.Row>
                </div>
                <div className='col-lg-6 form-body'>
                  <div className="flex flex-column text-center">
                    <img src={'/images/mm_logo.png'} alt='login' className='login-image' />
                    <div>
                      <div className="mm-logo-title">Mobility Measure</div>
                      <div className="mm-logo-subtitle">measuring for the future</div>
                    </div>
                  </div>
                </div>
              </Grid.Row>
              <PageFooter />
            </div>
          </Page>
        </div>
      )}
    />
  );
}

export default RegisterPage;
